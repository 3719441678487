import Vue, { Component } from 'vue';
import VueRouter, {
  RouteConfig,
  Route,
  RouteRecord,
  NavigationGuardNext
} from 'vue-router';
import type { EsModule } from 'vue/types/options';
import HomeView from '../../views/HomeView.vue';
import AuthView from '../../views/AuthView.vue';
import Auth from '@aws-amplify/auth';
import { DeviceType } from '../../typings';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { ADMIN_USER_GROUP, SUPPORT_USER_GROUP } from '../../constants';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthView,
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'account',
        name: 'account',
        component: (): Promise<EsModule<Component>> =>
          import(
            /* webpackChunkName: "account" */ '../../views/user/AccountView.vue'
          ),
        meta: { requiresAuth: true }
      },
      {
        path: 'gateways',
        name: 'gateways',
        component: (): Promise<EsModule<Component>> =>
          import(
            /* webpackChunkName: "gateways" */ '../../views/user/DeviceListView.vue'
          ),
        props: {
          deviceType: DeviceType.GATEWAY
        },
        meta: { requiresAuth: true },
        children: [
          {
            path: ':uid',
            name: 'gateway',
            component: (): Promise<EsModule<Component>> =>
              import(
                /* webpackChunkName: "gateway" */ '../../views/user/DeviceTableView.vue'
              ),
            props: (route: Route): Record<string, unknown> => ({
              deviceType: DeviceType.GATEWAY,
              uid: route.params.uid,
              searchTable:
                route.query?.search === undefined ? false : route.query.search
            }),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'interfaces',
        name: 'interfaces',
        component: (): Promise<EsModule<Component>> =>
          import(
            /* webpackChunkName: "interfaces" */ '../../views/user/DeviceListView.vue'
          ),
        props: {
          deviceType: DeviceType.INTERFACE
        },
        meta: { requiresAuth: true },
        children: [
          {
            path: ':uid',
            name: 'interface',
            component: (): Promise<EsModule<Component>> =>
              import(
                /* webpackChunkName: "interface" */ '../../views/user/DeviceTableView.vue'
              ),
            props: (route: Route): Record<string, unknown> => ({
              deviceType: DeviceType.INTERFACE,
              uid: route.params.uid,
              searchTable:
                route.query?.search === undefined ? false : route.query.search
            }),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'users',
        name: 'users',
        component: (): Promise<EsModule<Component>> =>
          import(
            /* webpackChunkName: "users" */ '../../views/admin/UserListView.vue'
          ),
        props: (route: Route): Record<string, unknown> => ({
          searchList: route.query?.search || ''
        }),
        meta: {
          requiresAuth: true,
          requiresGroup: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
        },
        children: [
          {
            path: ':sub',
            name: 'user',
            component: (): Promise<EsModule<Component>> =>
              import(
                /* webpackChunkName: "user" */ '../../views/admin/UserTableView.vue'
              ),
            props: (route: Route): Record<string, unknown> => ({
              sub: route.params.sub
            }),
            meta: {
              requiresAuth: true,
              requiresGroup: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
            }
          }
        ]
      },
      {
        path: 'production',
        name: 'production',
        component: (): Promise<EsModule<Component>> =>
          import(
            /* webpackChunkName: "production" */ '../../views/admin/ProductionTableView.vue'
          ),
        props: (route: Route): Record<string, unknown> => ({
          searchTable:
            route.query?.search === undefined ? false : route.query.search
        }),
        meta: {
          requiresAuth: true,
          requiresGroup: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
        }
      },
      {
        path: 'ota',
        name: 'ota',
        component: (): Promise<EsModule<Component>> =>
          import(
            /* webpackChunkName: "ota" */ '../../views/admin/OtaListView.vue'
          ),
        meta: { requiresAuth: true, requiresGroup: ADMIN_USER_GROUP },
        children: [
          {
            path: 'firmware',
            name: 'firmware',
            component: (): Promise<EsModule<Component>> =>
              import(
                /* webpackChunkName: "firmware" */ '../../views/admin/FirmwareTableView.vue'
              ),
            props: (route: Route): Record<string, unknown> => ({
              searchTable:
                route.query?.search === undefined ? false : route.query.search
            }),
            meta: { requiresAuth: true, requiresGroup: ADMIN_USER_GROUP }
          },
          {
            path: 'jobs',
            name: 'jobs',
            component: (): Promise<EsModule<Component>> =>
              import(
                /* webpackChunkName: "jobs" */ '../../views/admin/OtaJobTableView.vue'
              ),
            props: (route: Route): Record<string, unknown> => ({
              searchTable:
                route.query?.search === undefined ? false : route.query.search
            }),
            meta: { requiresAuth: true, requiresGroup: ADMIN_USER_GROUP }
          }
        ]
      },
      {
        path: 'shadows',
        name: 'shadows',
        component: (): Promise<EsModule<Component>> =>
          import(
            /* webpackChunkName: "shadows" */ '../../views/admin/ShadowListView.vue'
          ),
        props: (route: Route): Record<string, unknown> => ({
          searchList: route.query?.search || ''
        }),
        meta: {
          requiresAuth: true,
          requiresGroup: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
        },
        children: [
          {
            path: ':uid',
            name: 'shadow',
            component: (): Promise<EsModule<Component>> =>
              import(
                /* webpackChunkName: "shadow" */ '../../views/admin/ShadowEditorView.vue'
              ),
            props: (route: Route): Record<string, unknown> => ({
              uid: route.params.uid
            }),
            meta: {
              requiresAuth: true,
              requiresGroup: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
            }
          }
        ]
      },
      {
        path: 'logs',
        name: 'logs',
        component: (): Promise<EsModule<Component>> =>
          import(
            /* webpackChunkName: "logs" */ '../../views/admin/LogListView.vue'
          ),
        meta: {
          requiresAuth: true,
          requiresGroup: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
        },
        children: [
          {
            path: ':group',
            name: 'log',
            component: (): Promise<EsModule<Component>> =>
              import(
                /* webpackChunkName: "log" */ '../../views/admin/LogTableView.vue'
              ),
            props: (route: Route): Record<string, unknown> => ({
              logGroup: route.params.group,
              searchTable:
                route.query?.search === undefined ? false : route.query.search,
              startOffset: isNaN(parseInt(route.query?.start as string, 10))
                ? false
                : parseInt(route.query.start as string, 10)
            }),
            meta: {
              requiresAuth: true,
              requiresGroup: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
            }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router: VueRouter = new VueRouter({
  routes
});

router.beforeEach(
  async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
    const session: CognitoUserSession | undefined =
      await Auth.currentSession().catch((): undefined => undefined);
    const groups: string[] =
      session?.getIdToken()?.payload?.['cognito:groups'] || [];
    if (
      to.matched.some(
        (record: RouteRecord): boolean => record.meta.requiresAuth
      ) &&
      !session
    ) {
      return next({
        path: '/auth',
        ...(to.path && to.path !== '/'
          ? {
              query: {
                redirect: to.fullPath
              }
            }
          : {})
      });
    } else if (to.name === 'auth' && session) {
      return next({
        path: (to.query.redirect as string) || '/'
      });
    } else if (
      to.matched.some(
        (record: RouteRecord): boolean =>
          record.meta.requiresGroup &&
          !groups.some((group: string): boolean =>
            Array.isArray(record.meta.requiresGroup)
              ? record.meta.requiresGroup.includes(group)
              : record.meta.requiresGroup === group
          )
      )
    ) {
      return next({
        path: '/'
      });
    }
    return next();
  }
);

export default router;
