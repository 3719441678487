import './registerComponentHooks';
import './registerServiceWorker';

import Vue, { CreateElement, VNode } from 'vue';
import App from './App.vue';
import router from './plugins/router';
import vuetifyFactory from './plugins/vuetify';
import store, { SettingsModule } from './plugins/store';
import { Amplify } from '@aws-amplify/core';
import hljs from 'highlight.js';
import http from 'highlight.js/lib/languages/http';
import json from 'highlight.js/lib/languages/json';
import highlightjs from '@highlightjs/vue-plugin';
import moment from 'moment';
import 'moment/locale/de';
import vueMoment from 'vue-moment';
import { getModule } from 'vuex-module-decorators';
import type Vuetify from 'vuetify/lib';
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes';
import { AMPLIFY_CONFIG } from './constants';

Amplify.configure(AMPLIFY_CONFIG);

hljs.registerLanguage('http', http);
hljs.registerLanguage('json', json);
Vue.use(highlightjs);

const settingsStore: SettingsModule = getModule(SettingsModule);
const vuetify: Vuetify = vuetifyFactory({
  dark: settingsStore.actualDark,
  locale: settingsStore.actualLocale
});

moment.locale(settingsStore.actualLocale);
Vue.use(vueMoment, {
  moment
});

Vue.use(vueFilterPrettyBytes);

Vue.config.productionTip = false;

const app: Vue = new Vue({
  router,
  vuetify,
  store,
  render: (h: CreateElement): VNode => h(App)
});
store.$app = app;
app.$mount('#app');
