


























































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import type { ValidationRule, ValidationRules } from '../typings';
import {
  login,
  register,
  confirm,
  ConfirmType,
  resend,
  resetPassword
} from '../api/user';
import { Hub } from '@aws-amplify/core';
import { SettingsModule } from '../plugins/store';
import { getModule } from 'vuex-module-decorators';
import { noop } from 'vue-class-component/lib/util';

let signupDomains: string[] = [
  'codeatelier.com',
  'homee.de',
  'comtac.ch',
  'afriso.de',
  'e-steiermark.com'
];
if (process.env.SIGNUP_DOMAINS) {
  if (Array.isArray(process.env.SIGNUP_DOMAINS)) {
    signupDomains = process.env.SIGNUP_DOMAINS;
  } else if (typeof process.env.SIGNUP_DOMAINS === 'string') {
    signupDomains = process.env.SIGNUP_DOMAINS.split(',');
  }
}

enum AuthState {
  Login = 'Login',
  Register = 'Register',
  Reset = 'Reset',
  Confirm = 'Confirm'
}

const settingsStore: SettingsModule = getModule(SettingsModule);

@Component
export default class AuthView extends Vue {
  private readonly AuthState: typeof AuthState = AuthState;
  private readonly ConfirmType: typeof ConfirmType = ConfirmType;
  private readonly emailPattern: string =
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
  private readonly requiredRule: ValidationRule = (
    value: string
  ): boolean | string => !!value || '$vuetify.auth.REQUIRED';
  private readonly emailRules: ValidationRules = [
    (value: string): boolean | string =>
      !value || value.length <= 320 || '$vuetify.auth.TOO_LONG',
    (value: string): boolean | string =>
      !value ||
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ||
      '$vuetify.auth.INVALID_MAIL_ADDRESS'
  ];
  private readonly domainRule: ValidationRule = (
    value: string
  ): boolean | string =>
    signupDomains.some((domain: string): boolean => value.endsWith(domain)) ||
    '$vuetify.auth.DOMAIN_NOT_ALLOWED';
  private readonly passwordRules: ValidationRules = [
    (value: string): boolean | string =>
      !value || value.length >= 8 || '$vuetify.auth.TOO_SHORT',
    (value: string): boolean | string =>
      !value || value.length <= 99 || '$vuetify.auth.TOO_LONG',
    (value: string): boolean | string =>
      !value || /[a-z]/.test(value) || '$vuetify.auth.REQUIRE_LOWERCASE',
    (value: string): boolean | string =>
      !value || /[A-Z]/.test(value) || '$vuetify.auth.REQUIRE_UPPERCASE',
    (value: string): boolean | string =>
      !value || /\d/.test(value) || '$vuetify.auth.REQUIRE_NUMBERS',
    (value: string): boolean | string =>
      !value ||
      /[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(value) ||
      '$vuetify.auth.REQUIRE_SYMBOLS'
  ];
  private currentAuthState: AuthState = AuthState.Login;
  private valid: boolean = false;
  private loading: boolean = false;
  private email: string = '';
  private password: string = '';
  private firstname: string = '';
  private lastname: string = '';
  private confirmType: ConfirmType | null = null;
  private token: string | null = null;
  private customerId: string | null = null;

  public mounted(): void {
    if (this.$route.query?.confirm) {
      this.currentAuthState = AuthState.Confirm;
      this.confirmType = this.$route.query.confirm as ConfirmType;
      this.token = this.$route.query.token as string;
      this.customerId = this.$route.query.id as string;
      this.email = (this.$route.query.email as string) || '';
      if (
        ![
          ConfirmType.register,
          ConfirmType.email,
          ConfirmType.invite,
          ConfirmType.password
        ].includes(this.confirmType) ||
        !this.token ||
        !this.customerId ||
        !this.email
      ) {
        this.confirmType = this.token = this.customerId = null;
        this.$router.replace('/auth').catch(noop);
        return;
      }
      if (
        [ConfirmType.register, ConfirmType.email].includes(this.confirmType)
      ) {
        this.confirm();
      }
    }
    this.$router.replace('/auth').catch(noop);
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private login(): void {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    login(this.email, this.password)
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private register(): void {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    const locale: string =
      settingsStore.locale === 'system'
        ? (
            navigator.language ||
            (navigator as unknown as { browserLanguage: string })
              .browserLanguage ||
            (navigator as unknown as { userLanguage: string }).userLanguage ||
            'en-US'
          ).split('-')[0]
        : settingsStore.locale;
    register(this.email, this.password, this.firstname, this.lastname, locale)
      .then((): void => void (this.currentAuthState = AuthState.Confirm))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private confirm(): void {
    if (!this.confirmType || !this.token || !this.customerId || !this.email) {
      return;
    }
    this.loading = true;
    confirm(
      this.confirmType,
      this.customerId,
      this.token,
      this.password || undefined
    )
      .then((): void => {
        if (
          this.confirmType &&
          [ConfirmType.register, ConfirmType.email].includes(this.confirmType)
        ) {
          Hub.dispatch('appAlert', {
            event: 'success',
            message: this.$vuetify.lang.t(
              `$vuetify.auth.CONFIRM_MESSAGE_${this.confirmType.toUpperCase()}`
            )
          });
        }
        this.confirmType = this.token = this.customerId = null;
        this.currentAuthState = AuthState.Login;
        if (this.email && this.password) {
          this.login();
          return;
        }
        this.loading = false;
      })
      .catch((error: Error): void => {
        const type: ConfirmType = this.confirmType as ConfirmType;
        this.showError(error.message);
        this.confirmType = this.token = this.customerId = null;
        if (
          error.message.includes('410') &&
          [ConfirmType.register, ConfirmType.invite].includes(type)
        ) {
          this.resend();
          return;
        } else if (
          error.message.includes('410') &&
          type === ConfirmType.password
        ) {
          this.resetPassword();
          return;
        } else if (
          ![ConfirmType.invite, ConfirmType.password].includes(type)
        ) {
          this.currentAuthState = AuthState.Login;
        }
        this.loading = false;
      });
  }

  private resetPassword(): void {
    if (!this.email) {
      return;
    }
    this.loading = true;
    resetPassword(this.email)
      .then((): void => void (this.currentAuthState = AuthState.Confirm))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private resend(): void {
    if (!this.email) {
      return;
    }
    this.loading = true;
    resend(this.email)
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }
}
