


















































































































































import { Component, Vue } from 'vue-property-decorator';
import { mdiCog } from '@mdi/js';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { noop } from 'vue-class-component/lib/util';
import { Multipane, MultipaneResizer } from 'vue-multipane';
import ConsoleComponent from './components/ConsoleComponent.vue';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from './plugins/store';
import { VERSION } from './constants';

enum AlertTypes {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
}

const ALERT_TIMEOUT: number = 7000;
const settingsStore: SettingsModule = getModule(SettingsModule);

@Component({
  components: {
    Multipane,
    MultipaneResizer,
    ConsoleComponent
  }
})
export default class Home extends Vue {
  private readonly mdiCog: string = mdiCog;
  private readonly noop: typeof noop = noop;
  private readonly localeItems: Array<{
    value: 'system' | 'de' | 'en';
    text: string;
  }> = [
    {
      value: 'system',
      text: '$vuetify.app.LOCALE_SYSTEM'
    },
    {
      value: 'de',
      text: '$vuetify.app.LOCALE_DE'
    },
    {
      value: 'en',
      text: '$vuetify.app.LOCALE_EN'
    }
  ];
  private readonly darkItems: Array<{
    value: 'system' | boolean;
    text: string;
  }> = [
    {
      value: 'system',
      text: '$vuetify.app.DARK_SYSTEM'
    },
    {
      value: true,
      text: '$vuetify.app.DARK_ENABLED'
    },
    {
      value: false,
      text: '$vuetify.app.DARK_DISABLED'
    }
  ];
  private readonly consoleItems: Array<{
    value: boolean;
    text: string;
  }> = [
    {
      value: true,
      text: '$vuetify.app.CONSOLE_ENABLED'
    },
    {
      value: false,
      text: '$vuetify.app.CONSOLE_DISABLED'
    }
  ];
  private readonly ignoreVersionItems: Array<{
    value: boolean;
    text: string;
  }> = [
    {
      value: true,
      text: '$vuetify.app.IGNORE_VERSION_ENABLED'
    },
    {
      value: false,
      text: '$vuetify.app.IGNORE_VERSION_DISABLED'
    }
  ];

  private dialogShow: boolean = false;
  private dialogConsole: boolean = settingsStore.console;
  private dialogIgnoreVersion: boolean = settingsStore.ignoreVersion;
  private dialogDark: 'system' | boolean = settingsStore.dark;
  private dialogLocale: 'system' | 'de' | 'en' = settingsStore.locale;
  private alertAction: (() => void) | null = null;
  private alertMessage: string = '';
  private alertType: AlertTypes = AlertTypes.error;
  private alertTimeout: ReturnType<typeof setTimeout> | null = null;

  private get showConsole(): boolean {
    return settingsStore.console;
  }

  private get isDirty(): boolean {
    return (
      this.dialogConsole !== settingsStore.console ||
      this.dialogIgnoreVersion !== settingsStore.ignoreVersion ||
      this.dialogDark !== settingsStore.dark ||
      this.dialogLocale !== settingsStore.locale
    );
  }

  private get appVersion(): string {
    return VERSION;
  }

  private created(): void {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e: MediaQueryListEvent): void => {
        if (settingsStore.dark === 'system') {
          this.$vuetify.theme.dark = !!e.matches;
        }
      });
    window.addEventListener('languagechange', (): void => {
      this.$vuetify.lang.current = settingsStore.actualLocale;
      this.$moment.locale(settingsStore.actualLocale);
    });
    Hub.listen('auth', ({ payload }: HubCapsule): void => {
      if (payload.event === 'signIn') {
        this.$router
          .replace((this.$route.query.redirect as string) || '/')
          .catch(noop);
      } else if (payload.event === 'signOut') {
        this.$router
          .replace({
            path: 'auth',
            query: {
              redirect: this.$route.fullPath
            }
          })
          .catch(noop);
      }
    });
    Hub.listen('appAlert', ({ payload }: HubCapsule): void => {
      this.showAlert(
        payload.event as AlertTypes,
        payload.message?.startsWith('$vuetify.')
          ? this.$vuetify.lang.t(payload.message)
          : payload.message,
        typeof payload.data === 'function' ? payload.data : undefined
      );
    });
  }

  private showAlert(
    type: AlertTypes,
    message?: string,
    action?: () => void
  ): void {
    if (this.alertTimeout) {
      clearTimeout(this.alertTimeout);
    }
    if (!message) {
      return;
    }
    this.alertType = type;
    this.alertAction = action || null;
    this.alertMessage = message;
    this.alertTimeout = setTimeout(this.hideAlert, ALERT_TIMEOUT);
  }

  private hideAlert(): void {
    if (this.alertTimeout) {
      clearTimeout(this.alertTimeout);
    }
    this.alertAction = null;
    this.alertMessage = '';
    this.alertTimeout = null;
  }

  private resetDialog(): void {
    this.dialogShow = false;
    this.dialogConsole = settingsStore.console;
    this.dialogIgnoreVersion = settingsStore.ignoreVersion;
    this.dialogDark = settingsStore.dark;
    this.dialogLocale = settingsStore.locale;
  }

  private saveSettings(): void {
    this.dialogShow = false;
    settingsStore.setConsole(this.dialogConsole);
    settingsStore.setIgnoreVersion(this.dialogIgnoreVersion);
    settingsStore.setDark(this.dialogDark);
    settingsStore.setLocale(this.dialogLocale);
  }
}
