




















































































































































import { Vue, Component } from 'vue-property-decorator';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import { mdiDelete } from '@mdi/js';

@Component({
  components: {
    Splitpanes,
    Pane
  }
})
export default class ConsoleComponent extends Vue {
  private readonly mdiDelete: string = mdiDelete;

  private requests: string[] = [];
  private responses: string[] = [];

  private created(): void {
    Hub.listen('appConsole', ({ payload }: HubCapsule): void => {
      if (payload.event === 'request') {
        this.requests = this.requests
          .slice(this.requests.length >= 20 ? 1 : 0)
          .concat(payload.data);
        this.$nextTick().then((): void => {
          const elem: Element | undefined = (this.$refs?.requests as Vue)
            ?.$el;
          if (elem) {
            elem.scrollTop = elem.scrollHeight;
          }
        });
      } else if (payload.event === 'response') {
        this.responses = this.responses
          .slice(this.responses.length >= 20 ? 1 : 0)
          .concat(payload.data);
        this.$nextTick().then((): void => {
          const elem: Element | undefined = (this.$refs?.responses as Vue)
            ?.$el;
          if (elem) {
            elem.scrollTop = elem.scrollHeight;
          }
        });
      }
    });
  }
}
