import { de } from 'vuetify/src/locale';

export default {
  ...de,
  app: {
    SETTINGS: 'Einstellungen',
    LOCALE: 'Sprache',
    LOCALE_SYSTEM: 'Systemsprache',
    LOCALE_DE: 'Deutsch',
    LOCALE_EN: 'Englisch',
    DARK: 'Dark Mode',
    DARK_SYSTEM: 'Systemeinstellung',
    DARK_ENABLED: 'An',
    DARK_DISABLED: 'Aus',
    CONSOLE: 'HTTP Konsole',
    CONSOLE_ENABLED: 'An',
    CONSOLE_DISABLED: 'Aus',
    IGNORE_VERSION: 'ESP Version ignorieren',
    IGNORE_VERSION_ENABLED: 'An',
    IGNORE_VERSION_DISABLED: 'Aus',
    CANCEL: 'Abbrechen',
    SAVE: 'Speichern',
    UPDATE:
      'Eine neue Version der Stromee Plus DevApp ist verfügbar. Zum Aktualisieren klicken.'
  },
  auth: {
    BACK_TO_SIGN_IN: 'Zurück zur Anmeldung',
    CODE_LABEL: 'Verifizierungscode *',
    CODE_PLACEHOLDER: 'Verifizierungscode eingeben',
    CODE_SENT: 'Verifizierungscode wurde versendet.',
    CONFIRM_BUTTON: 'Bestätigen',
    CONFIRM_USER_HEADER: 'Konto bestätigen',
    DOMAIN_NOT_ALLOWED: 'Domain ist nicht erlaubt.',
    EMAIL_LABEL: 'E-Mail Adresse *',
    EMAIL_PLACEHOLDER: 'E-Mail Adresse eingeben',
    FIRSTNAME_LABEL: 'Vorname *',
    FIRSTNAME_PLACEHOLDER: 'Vorname eingeben',
    LASTNAME_LABEL: 'Nachname *',
    LASTNAME_PLACEHOLDER: 'Nachname eingeben',
    FORGOT_PASSWORD: 'Passwort vergessen?',
    HAVE_ACCOUNT: 'Bereits registriert?',
    INVALID_MAIL_ADDRESS: 'Ungültige E-Mail Adresse.',
    LOGIN: 'Anmelden',
    LOGIN_BUTTON: 'Anmelden',
    LOGIN_HEADER: 'Anmelden',
    LOST_CODE: 'Verifizierungscode verloren?',
    NAME_LABEL: 'Name *',
    NAME_PLACEHOLDER: 'Vollständigen Namen eingeben',
    NEW_PASSWORD_LABEL: 'Neues Passwort *',
    NEW_PASSWORD_PLACEHOLDER: 'Neues Passwort eingeben',
    NO_ACCOUNT: 'Noch kein Konto?',
    ONLY_NUMBERS: 'Darf nur Nummern enthalten.',
    PASSWORD_A_PLACEHOLDER: 'Ein Passwort eingeben',
    PASSWORD_LABEL: 'Passwort *',
    PASSWORD_PLACEHOLDER: 'Passwort eingeben',
    REGISTER: 'Registrieren',
    REGISTER_BUTTON: 'Registrieren',
    REGISTER_HEADER: 'Registrieren',
    REQUIRE_LOWERCASE: 'Muss Kleinbuchstaben enthalten.',
    REQUIRE_NUMBERS: 'Muss Nummern enthalten.',
    REQUIRE_SYMBOLS: 'Muss Symbole enthalten.',
    REQUIRE_UPPERCASE: 'Muss Großbuchstaben enthalten.',
    REQUIRED: 'Pflichtfeld.',
    RESET_PASSWORD: 'Zurücksetzen',
    RESET_PASSWORD_HEADER: 'Passwort Zurücksetzen',
    SEND_LINK: 'Link anfordern',
    TOO_LONG: 'Zu lang.',
    TOO_SHORT: 'Zu kurz.',
    CONFIRM_HEADER: 'E-Mail Adresse verifizieren',
    CHECK_YOUR_EMAIL:
      'Ein Verifizierungsmail wurde versandt. Bitte Postfach prüfen.',
    PASSWORD_HEADER: 'Neues Passwort festlegen',
    PASSWORD_BUTTON: 'Speichern',
    CONFIRM_MESSAGE_REGISTER: 'Registrierung abgeschlossen. Bitte anmelden.',
    CONFIRM_MESSAGE_EMAIL: 'E-Mail Adresse bestätigt. Bitte anmelden.'
  },
  console: {
    CLEAR: 'Leeren',
    REQUESTS: 'Anfragen',
    RESPONSES: 'Antworten'
  },
  home: {
    REGULAR_USER: 'Regulärer Benutzer',
    FEATURES: 'Funktionen',
    SUPPORT: 'Support',
    ADMIN: 'Admin',
    ACCOUNT: 'Konto',
    GATEWAYS: 'Gateways',
    INTERFACES: 'Interfaces',
    USERS: 'Alle Benutzer',
    ALL_DEVICES: 'Produzierte Geräte',
    SHADOWS: 'Provisionierte Geräte',
    HISTORY: 'Verlaufsdatenbank',
    OTA: 'OTA Updates',
    LOGS: 'Logging'
  },
  account: {
    ACCOUNT: 'Konto',
    LOGOUT: 'Abmelden'
  },
  devices: {
    GATEWAYS: 'Gateways',
    INTERFACES: 'Interfaces',
    REFRESH: 'Aktualisieren',
    SEARCH: 'Suchen',
    ADD_GATEWAYS: 'Gateway hinzufügen',
    ADD_INTERFACES: 'Interface hinzufügen',
    REMOVE_GATEWAYS: 'Gateway entfernen',
    REMOVE_INTERFACES: 'Interface entfernen',
    CONFIRM_REMOVE_GATEWAYS: 'Soll das Gateway wirklich entfernt werden?',
    CONFIRM_REMOVE_INTERFACES: 'Soll das Interface wirklich entfernt werden?',
    ADD: 'Hinzufügen',
    REMOVE: 'Entfernen',
    CANCEL: 'Abbrechen',
    REQUIRED_FIELD: '* kennzeichnet Pflichtfeld',
    REQUIRED: 'Pflichtfeld.',
    UID: 'UID *',
    VERIFICATION: 'Verifizierungscode *',
    SHOW_SCANNER: 'QR Code scannen',
    HIDE_SCANNER: 'Daten manuell eingeben',
    STATE_CONNECTED_SINCE: 'Verbunden ',
    STATE_DISCONNECTED_SINCE: 'Getrennt ',
    STATE_UNKNOWN: 'Keine Daten'
  },
  device: {
    STATE: 'State',
    REFRESH: 'Aktualisieren',
    REFRESH_ALL: 'Alles aktualisieren',
    ADD_LORA: 'Interface über Lora verbinden',
    PROPERTY: 'Eigenschaft',
    REPORTED: 'Ist-Wert',
    DESIRED: 'Soll-Wert',
    ADD: 'Hinzufügen',
    CANCEL: 'Abbrechen',
    SAVE: 'Speichern',
    REMOVE: 'Entfernen',
    REQUIRED_FIELD: '* kennzeichnet Pflichtfeld',
    REQUIRED: 'Pflichtfeld.',
    INVALID: 'Ungültig.',
    UID: 'UID *',
    VERIFICATION: 'Verifizierungscode *',
    REMOVE_INTERFACES: 'Interface entfernen',
    CONFIRM_REMOVE_INTERFACES: 'Soll das Interface wirklich entfernt werden?',
    COLLAPSE: 'Zuklappen',
    EXPAND: 'Aufklappen',
    SEARCH: 'Eigenschaft Suchen',
    OPEN_PRODUCTION: 'In Produktionstabelle anzeigen',
    OPEN_SHADOW: 'Shadow öffnen',
    OPEN_LOGS: 'Geräte Logs einsehen',
    SHOW_SCANNER: 'QR Code scannen',
    HIDE_SCANNER: 'Daten manuell eingeben',
    METER_CONFIG: 'Zählerkonfiguration',
    UPDATE_PROGRESS: 'Updatefortschritt',
    SELF_TEST: 'Selbsttest',
    CLOSE: 'Schließen',
    REVOLUTIONS_FACTOR: 'Umdrehungen pro kWh',
    LIVE: 'Regelmäßig Aktualisieren',
    SHOW_CHART: 'Verlauf anzeigen'
  },
  chart: {
    DURATION: 'Zeitraum',
    DURATION_TENMINUTES: '10 Minuten',
    DURATION_ONEHOUR: '1 Stunde',
    DURATION_SIXHOURS: '6 Stunden',
    DURATION_TWELFHOURS: '12 Stunden',
    DURATION_ONEDAY: '1 Tag',
    DURATION_ONEWEEK: '1 Woche',
    DURATION_ONEMONTH: '1 Monat',
    DURATION_SIXMONTHS: '6 Monate',
    DURATION_ONEYEAR: '1 Jahr',
    REFRESH: 'Aktualisieren',
    DELETE_HISTORY: 'Verlaufsdaten löschen',
    CONFIRM_DELETE:
      'Sollen die Verlaufsdaten für diesen Messwert wirklich gelöscht werden?',
    CANCEL: 'Abbrechen',
    DELETE: 'Löschen'
  },
  production: {
    TABLE_HEADER: 'Produzierte Geräte',
    SEARCH: 'Suchen',
    ADD_DEVICE: 'Gerät hinzufügen',
    REFRESH: 'Aktualisieren',
    CANCEL: 'Abbrechen',
    SAVE: 'Speichern',
    VERIFICATION_REQUIRED: 'Verifizierungscode *',
    UID_REQUIRED: 'UID *',
    DEVICE_TYPE_REQUIRED: 'Gerätetyp *',
    VERIFICATION: 'Verifizierungscode',
    UID: 'UID',
    DEVICE_TYPE: 'Gerätetyp',
    REQUIRED_FIELD: '* kennzeichnet Pflichtfeld',
    ADD: 'Hinzufügen',
    REMOVE: 'Entfernen',
    REMOVE_DEVICE: 'Gerät entfernen',
    CONFIRM_REMOVE: 'Soll das Gerät wirklich entfernt werden?',
    REMOVE_LINK: 'Verknüpfung mit dem Benutzer aufheben',
    REMOVE_LINK_SHORT: 'Verknüpfung aufheben',
    CONFIRM_REMOVE_LINK:
      'Soll die Verknüpfung mit diesem Benutzer wirklich aufgehoben werden?',
    REMOVE_SOFT: 'Aufheben',
    REQUIRED: 'Pflichtfeld.',
    CREATED: 'Produktionsdatum',
    CHANGED: 'Zuletzt bearbeitet',
    GATEWAY: 'Gateway',
    INTERFACE: 'Interface',
    OTHER: 'Anderes',
    INVALID: 'Falsches Format.',
    EDIT: 'Bearbeiten',
    USER: 'Benutzer',
    PROVISIONED: 'Provisioniert',
    OPEN_USER: 'Verknüpften Benutzer öffnen',
    QR_CODE: 'QR Code',
    OPEN_QR_CODE: 'QR Code öffnen',
    OPEN_SHADOW: 'Shadow öffnen',
    CLOSE: 'Schließen',
    SET_VERIFICATION: 'Verifizierungsflag setzen',
    SET_VERIFICATION_COMPLETE: 'Verifizierungsflag wurde gesetzt',
    CONNECTION_STATE: 'Verbindung',
    STATE_CONNECTED_SINCE: 'Verbunden ',
    STATE_DISCONNECTED_SINCE: 'Getrennt ',
    STATE_UNKNOWN: 'Keine Daten'
  },
  shadows: {
    SHADOWS: 'Provisionierte Geräte',
    REFRESH: 'Aktualisieren',
    REMOVE_DEVICE: 'Provisionierung aufheben',
    CONFIRM_REMOVE:
      'Soll die Provisionierung dieses Geräts wirklich aufgehoben werden?',
    REMOVE: 'Aufheben',
    CANCEL: 'Abbrechen',
    GATEWAYS: 'Gateway',
    INTERFACES: 'Interface',
    OTHERS: 'Anderes',
    SEARCH: 'Suchen'
  },
  shadow: {
    SHADOW: 'Shadow',
    REFRESH: 'Aktualisieren',
    SAVE: 'Speichern',
    OPEN_PRODUCTION: 'In Produktionstabelle anzeigen',
    OPEN_USER: 'Verknüpften Benutzer öffnen',
    SET_VERIFICATION: 'Verifizierungsflag setzen',
    REMOVE_LINK: 'Verknüpfung mit dem Benutzer aufheben',
    REMOVE_LINK_SHORT: 'Verknüpfung aufheben',
    CONFIRM_REMOVE_LINK:
      'Soll die Verknüpfung mit diesem Benutzer wirklich aufgehoben werden?',
    REMOVE: 'Aufheben',
    CANCEL: 'Abbrechen',
    UNSAVED_CHANGES: 'Ungespeicherte Änderungen',
    CONFIRM_UNSAVED_CHANGES: 'Sollen die Änderungen wirklich verworfen werden?',
    DISCARD: 'Verwerfen',
    OPEN_LOGS: 'Geräte Logs einsehen'
  },
  users: {
    USERS: 'Alle Benutzer',
    REFRESH: 'Aktualisieren',
    REMOVE_USER: 'Benutzer entfernen',
    CONFIRM_REMOVE_USER: 'Soll dieser Benutzer wirklich entfernt werden?',
    CANCEL: 'Abbrechen',
    REMOVE: 'Entfernen',
    REGULAR_USER: 'Regulärer Benutzer',
    SUPPORT: 'Support',
    ADMIN: 'Admin',
    INVITE_USERS: 'Benutzer einladen',
    INVITE_SUCCESS: 'Erfolgreich: ',
    INVITE_RESEND: 'Erinnert: ',
    INVITE_ERROR: 'Fehlgeschlagen: '
  },
  invite: {
    INVITE_USERS: 'Benutzer einladen',
    CANCEL: 'Abbrechen',
    INVITE: 'Einladen',
    REQUIRED_FIELD: '* kennzeichnet Pflichtfeld',
    SINGLE: 'Einzeln',
    MULTIPLE: 'Mehrere',
    EMAIL_LABEL: 'E-Mail Adresse *',
    EMAIL_PLACEHOLDER: 'E-Mail Adresse eingeben',
    FIRSTNAME_LABEL: 'Vorname *',
    FIRSTNAME_PLACEHOLDER: 'Vorname eingeben',
    LASTNAME_LABEL: 'Nachname *',
    LASTNAME_PLACEHOLDER: 'Nachname eingeben',
    FILE_LABEL: 'Datei *',
    FILE_PLACEHOLDER: 'Excel Datei auswählen',
    SHEET_LABEL: 'Tabellenblatt *',
    SHEET_PLACEHOLDER: 'Tabellenblatt auswählen',
    FIRSTNAME_COLUMN_LABEL: 'Spalte für Vornamen *',
    FIRSTNAME_COLUMN_PLACEHOLDER: 'Spalte auswählen',
    LASTNAME_COLUMN_LABEL: 'Spalte für Nachnamen *',
    LASTNAME_COLUMN_PLACEHOLDER: 'Spalte auswählen',
    EMAIL_COLUMN_LABEL: 'Spalte für E-Mail Adressen *',
    EMAIL_COLUMN_PLACEHOLDER: 'Spalte auswählen',
    REQUIRED: 'Pflichtfeld.',
    TOO_LONG: 'Zu lang.',
    TOO_LARGE: 'Zu groß.',
    INVALID_MAIL_ADDRESS: 'Ungültige E-Mail Adresse.',
    PARSE_ERROR: 'XLSX Datei konnte nicht geparst werden!'
  },
  user: {
    COGNITO_USER: 'Cognito Benutzer',
    OPEN_PRODUCTION: 'Verknüpfte Geräte suchen',
    REFRESH: 'Aktualisieren',
    PROPERTY: 'Eigenschaft',
    VALUE: 'Wert',
    GLOBAL_SIGNOUT: 'Alle Tokens invalidieren',
    CONFIRM_GLOBAL_SIGNOUT:
      'Soll wirklich alle Tokens für diesen Benutzer invalidiert werden?',
    SIGNOUT: 'Invalidieren',
    CANCEL: 'Abbrechen',
    REGULAR_USER: 'Regulärer Benutzer',
    SUPPORT: 'Support',
    ADMIN: 'Admin'
  },
  logs: {
    STROMEE_PLUS_LOG_GROUPS: 'Stromee Plus Logs',
    STROMEE_LOG_GROUPS: 'Stromee Logs',
    DEVICE_LOGS: 'Geräte',
    PROVISIONING_LOGS: 'Provisionierung',
    IOT_LOGS: 'AWS IoT Core',
    API_LOGS: 'Stromee Plus API',
    FLAG_LOGS: 'Verifizierungsflag',
    HISTORY_LOGS: 'Verlaufsdaten',
    CONNECTION_LOGS: 'Verbindungsstatus',
    SIGNUP_LOGS: 'Cognito Registrierung',
    MIGRATION_LOGS: 'Cognito Migration',
    CUSTOMER_API_LOGS: 'Kunden API',
    EMAIL_LOGS: 'E-Mail Versandt'
  },
  log: {
    TABLE_HEADER: 'Logs',
    SEARCH: 'Suchen',
    REFRESH: 'Aktualisieren',
    OPEN_CLOUDWATCH: 'In CloudWatch öffnen',
    TIMESTAMP: 'Zeit',
    MESSAGE: 'Nachricht',
    TIMESPAN: 'Zeitraum:'
  },
  ota: {
    OTA: 'OTA Updates',
    FIRMWARE: 'Firmware Versionen',
    UPDATES: 'Update Jobs'
  },
  jobs: {
    TABLE_HEADER: 'Update Jobs',
    SEARCH: 'Suchen',
    ADD_JOB: 'Job erstellen',
    REFRESH: 'Aktualisieren',
    OPEN_FIRMWARE: 'Zur Firmware springen',
    OPEN_DEVICE: 'Zu Ziel springen',
    OPEN_DEVICES: 'Zu Zielen springen',
    ALL_GATEWAYS: 'Alle Gateways',
    ALL_INTERFACES: 'Alle Interfaces',
    ALL_OTHERS: 'Alle Anderen',
    ALL_DEVICES: 'Alle Geräte',
    REMOVE_JOB: 'Job entfernen',
    REQUIRED_FIELD: '* kennzeichnet Pflichtfeld',
    CANCEL: 'Abbrechen',
    ADD: 'Erstellen',
    CONFIRM_REMOVE: 'Soll der Update Job wirklich entfernt werden?',
    REMOVE: 'Entfernen',
    UNIQUE: 'Muss einzigartig sein.',
    REQUIRED: 'Pflichtfeld.',
    INVALID: 'Ungültig.',
    NAME: 'Name',
    FIRMWARE: 'Firmware',
    STRATEGY: 'Zielauswahl',
    TARGETS: 'Ziele',
    CREATED: 'Erstelldatum',
    CONTINUOUS: 'Fortlaufend',
    SNAPSHOT: 'Momentaufnahme',
    NAME_REQUIRED: 'Name *',
    FIRMWARE_REQUIRED: 'Firmware *',
    VERSION_REQUIRED: 'Version *',
    STRATEGY_REQUIRED: 'Zielauswahl *',
    TARGETS_REQUIRED: 'Ziele *',
    GROUPS: 'Gruppen',
    GATEWAYS: 'Gateways',
    INTERFACES: 'Interfaces',
    OTHERS: 'Andere',
    SHOW_STATUS: 'Updatestatus anzeigen',
    UPDATE_STATUS: 'Updatestatus',
    CLOSE: 'Schließen'
  },
  firmware: {
    TABLE_HEADER: 'Firmware Versionen',
    SEARCH: 'Suchen',
    ADD_FIRMWARE: 'Firmware hinzufügen',
    REFRESH: 'Aktualisieren',
    REMOVE_FIRMWARE: 'Firmware entfernen',
    NAME_REQUIRED: 'Name *',
    FILE_REQUIRED: 'Firmwaredatei *',
    REQUIRED_FIELD: '* kennzeichnet Pflichtfeld',
    CANCEL: 'Abbrechen',
    ADD: 'Hinzufügen',
    CONFIRM_REMOVE: 'Soll diese Firmware Version wirklich entfernt werden?',
    REMOVE: 'Entfernen',
    REQUIRED: 'Pflichtfeld.',
    TOO_LARGE: 'Zu groß.',
    NAME: 'Name',
    SIZE: 'Dateigröße',
    MODIFIED: 'Änderungsdatum',
    OPEN_JOB: 'Nach zugehörigen Update Jobs suchen',
    DOWNLOAD_FORMWARE: 'Firmware herunterladen'
  },
  meter: {
    TEMPLATE: 'Konfigurationsvorlage',
    TEMPLATE_REQUIRED: 'Konfigurationsvorlage *',
    BASIC: 'Hex String',
    EXPERT: 'Parameter',
    BASIC_CONFIG: 'Schnittstellenkonfiguration *',
    DATA_CONFIG: 'OBIS-Codes *',
    TYPE_REQUIRED: 'Schnitstellentyp *',
    BAUD_REQUIRED: 'Baudrate *',
    PARITY_REQUIRED: 'Parität *',
    STOPBITS_REQUIRED: 'Stopbits *',
    LENGTH_REQUIRED: 'Datenlänge *',
    INVERSION_REQUIRED: 'Invertierung der Polarität *',
    MODE_REQUIRED: 'Betriebsart *',
    INTERVAL_REQUIRED: 'Intervall (in Sekunden) *',
    PROTOCOL_REQUIRED: 'Protokoll *',
    SECURITY_REQUIRED: 'Verschlüsselung *',
    KEY1_REQUIRED: 'Authentifizierungsschlüssel (GAK) *',
    KEY2_REQUIRED: 'Verschlüsselungsschlüssel (GUEK) *',
    REQUIRED: 'Pflichtfeld.',
    TOO_MANY: 'Zu viele.',
    TOO_LONG: 'Zu lang.',
    INVALID: 'Ungültig.'
  },
  executions: {
    RETRY_EXECUTION: 'Update für das ausgewählte Gerät wiederholen',
    RETRY_EXECUTIONS: 'Update für die ausgewählten Geräte wiederholen',
    CANCEL_EXECUTION: 'Update für das ausgewählte Gerät abbrechen',
    CANCEL_EXECUTIONS: 'Update für die ausgewählten Geräte abbrechen',
    SEARCH: 'Suchen',
    REFRESH: 'Aktualisieren',
    UID: 'UID',
    STATUS: 'Status',
    CANCELED: 'Abgebrochen',
    FAILED: 'Fehlgeschlagen',
    IN_PROGRESS: 'Update läuft',
    QUEUED: 'In Warteschlange',
    REJECTED: 'Zurückgewiesen',
    REMOVED: 'Entfernt',
    SUCCEEDED: 'Erfolgreich',
    TIMED_OUT: 'Zeitüberschreitung',
    OPEN_SHADOW: 'Shadow öffnen'
  }
};
