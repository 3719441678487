import { en } from 'vuetify/src/locale';

export default {
  ...en,
  app: {
    SETTINGS: 'Settings',
    LOCALE: 'Locale',
    LOCALE_SYSTEM: 'System Locale',
    LOCALE_DE: 'German',
    LOCALE_EN: 'English',
    DARK: 'Dark Mode',
    DARK_SYSTEM: 'System Default',
    DARK_ENABLED: 'Enabled',
    DARK_DISABLED: 'Disabled',
    CONSOLE: 'HTTP Console',
    CONSOLE_ENABLED: 'Enabled',
    CONSOLE_DISABLED: 'Disabled',
    IGNORE_VERSION: 'Ignore ESP Version',
    IGNORE_VERSION_ENABLED: 'Enabled',
    IGNORE_VERSION_DISABLED: 'Disabled',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    UPDATE:
      'A new Version of the Stromee Plus DevApp is available. Click to update.'
  },
  auth: {
    BACK_TO_SIGN_IN: 'Back to Login',
    CODE_LABEL: 'Verification Code *',
    CODE_PLACEHOLDER: 'Enter the Verification Code',
    CODE_SENT: 'Verification Code has been sent.',
    CONFIRM_BUTTON: 'Confirm',
    CONFIRM_USER_HEADER: 'Confirm Account',
    DOMAIN_NOT_ALLOWED: 'Domain not allowed.',
    EMAIL_LABEL: 'Email Address *',
    EMAIL_PLACEHOLDER: 'Enter your Email Address',
    FIRSTNAME_LABEL: 'First Name *',
    FIRSTNAME_PLACEHOLDER: 'Enter your First Name',
    LASTNAME_LABEL: 'Last Name *',
    LASTNAME_PLACEHOLDER: 'Enter your Last Name',
    FORGOT_PASSWORD: 'Forgot your Password?',
    HAVE_ACCOUNT: 'Have an Account?',
    INVALID_MAIL_ADDRESS: 'Invalid Mail Address.',
    LOGIN: 'Login',
    LOGIN_BUTTON: 'Login',
    LOGIN_HEADER: 'Login to your Account',
    LOST_CODE: 'Lost your Code?',
    NAME_LABEL: 'Full Name *',
    NAME_PLACEHOLDER: 'Enter your full Name',
    NEW_PASSWORD_LABEL: 'New Password *',
    NEW_PASSWORD_PLACEHOLDER: 'Enter a new Password',
    NO_ACCOUNT: 'No Account?',
    ONLY_NUMBERS: 'Must only contain numeric Characters.',
    PASSWORD_A_PLACEHOLDER: 'Enter a Password',
    PASSWORD_LABEL: 'Password *',
    PASSWORD_PLACEHOLDER: 'Enter your Password',
    REGISTER: 'Create Account',
    REGISTER_BUTTON: 'Create Account',
    REGISTER_HEADER: 'Create a new Account',
    REQUIRE_LOWERCASE: 'Must contain lowercase Characters.',
    REQUIRE_NUMBERS: 'Must contain numeric Characters.',
    REQUIRE_SYMBOLS: 'Must contain symbol Characters.',
    REQUIRE_UPPERCASE: 'Must contain uppercase Characters.',
    REQUIRED: 'Required.',
    RESET_PASSWORD: 'Reset Password',
    RESET_PASSWORD_HEADER: 'Reset your Password',
    SEND_Link: 'Send Link',
    TOO_LONG: 'Is too long.',
    TOO_SHORT: 'Is too short.',
    CONFIRM_HEADER: 'Confirm mail address',
    CHECK_YOUR_EMAIL:
      'A verification mail has been sent. Please check your inbox.',
    PASSWORD_HEADER: 'Set a new password',
    PASSWORD_BUTTON: 'Save',
    CONFIRM_MESSAGE_REGISTER: 'Registration complete. Please login.',
    CONFIRM_MESSAGE_EMAIL: 'Mail address confirmed. Please login.'
  },
  console: {
    CLEAR: 'Clear',
    REQUESTS: 'Requests',
    RESPONSES: 'Responses'
  },
  home: {
    REGULAR_USER: 'Regular User',
    FEATURES: 'Features',
    SUPPORT: 'Support',
    ADMIN: 'Admin',
    ACCOUNT: 'Account',
    GATEWAYS: 'Gateways',
    INTERFACES: 'Interfaces',
    USERS: 'All Users',
    ALL_DEVICES: 'Produced Devices',
    SHADOWS: 'Provisioned Devices',
    HISTORY: 'History Database',
    OTA: 'OTA Updates',
    LOGS: 'Logs'
  },
  account: {
    ACCOUNT: 'Account',
    LOGOUT: 'Logout'
  },
  devices: {
    GATEWAYS: 'Gateways',
    INTERFACES: 'Interfaces',
    REFRESH: 'Refresh',
    SEARCH: 'Search',
    ADD_GATEWAYS: 'Link Gateway',
    ADD_INTERFACES: 'Add Interface',
    REMOVE_GATEWAYS: 'Remove Gateway',
    REMOVE_INTERFACES: 'Remove Interface',
    CONFIRM_REMOVE_GATEWAYS: 'Do you really want to remove this Gateway?',
    CONFIRM_REMOVE_INTERFACES: 'Do you really want to remove this Interface?',
    ADD: 'Add',
    REMOVE: 'Remove',
    CANCEL: 'Cancel',
    REQUIRED_FIELD: '* indicates required Field',
    REQUIRED: 'Required.',
    UID: 'UID *',
    VERIFICATION: 'Verification Code *',
    SHOW_SCANNER: 'Scan QR Code',
    HIDE_SCANNER: 'Enter Data manually',
    STATE_CONNECTED_SINCE: 'Connected ',
    STATE_DISCONNECTED_SINCE: 'Disconnected ',
    STATE_UNKNOWN: 'No Data'
  },
  device: {
    STATE: 'State',
    REFRESH: 'Refresh',
    REFRESH_ALL: 'Refresh all',
    ADD_LORA: 'Connect Interface over Lora',
    PROPERTY: 'Property',
    REPORTED: 'Reported',
    DESIRED: 'Desired',
    ADD: 'Add',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    REMOVE: 'Remove',
    REQUIRED_FIELD: '* indicates required Field',
    REQUIRED: 'Required.',
    INVALID: 'Invalid.',
    UID: 'UID *',
    VERIFICATION: 'Verification Code *',
    REMOVE_INTERFACES: 'Remove Interface',
    CONFIRM_REMOVE_INTERFACES: 'Do you really want to remove this Interface?',
    COLLAPSE: 'Collapse',
    EXPAND: 'Expand',
    SEARCH: 'Search Property',
    OPEN_PRODUCTION: 'Show in Production Table',
    OPEN_SHADOW: 'Open Shadow',
    OPEN_LOGS: 'Open Device Logs',
    SHOW_SCANNER: 'Scan QR Code',
    HIDE_SCANNER: 'Enter Data manually',
    METER_CONFIG: 'Meter Config',
    UPDATE_PROGRESS: 'Update Progress',
    SELF_TEST: 'Self Test',
    CLOSE: 'Close',
    REVOLUTIONS_FACTOR: 'Revolutions per kWh',
    LIVE: 'Periodic Refresh',
    SHOW_CHART: 'Show History'
  },
  chart: {
    DURATION: 'Timespan',
    DURATION_TENMINUTES: '10 Minutes',
    DURATION_ONEHOUR: '1 Hour',
    DURATION_SIXHOURS: '6 Hours',
    DURATION_TWELFHOURS: '12 Hours',
    DURATION_ONEDAY: '1 Day',
    DURATION_ONEWEEK: '1 Week',
    DURATION_ONEMONTH: '1 Month',
    DURATION_SIXMONTHS: '6 Months',
    DURATION_ONEYEAR: '1 Year',
    REFRESH: 'Refresh',
    DELETE_HISTORY: 'Delete History Data',
    CONFIRM_DELETE:
      'Do you really want to delete the History Data for this Measurement?',
    CANCEL: 'Cancel',
    DELETE: 'Delete'
  },
  production: {
    TABLE_HEADER: 'Produced Devices',
    SEARCH: 'Search',
    ADD_DEVICE: 'Add Device',
    REFRESH: 'Refresh',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    VERIFICATION_REQUIRED: 'Verification Code *',
    UID_REQUIRED: 'UID *',
    DEVICE_TYPE_REQUIRED: 'Device Type *',
    VERIFICATION: 'Verification Code',
    UID: 'UID',
    DEVICE_TYPE: 'Device Type',
    REQUIRED_FIELD: '* indicates required Field',
    ADD: 'Add',
    REMOVE: 'Remove',
    REMOVE_DEVICE: 'Remove Device',
    CONFIRM_REMOVE: 'Do you really want to remove this Device?',
    REMOVE_LINK: 'Remove Link with User',
    REMOVE_LINK_SHORT: 'Remove Link',
    CONFIRM_REMOVE_LINK:
      'Do you really want to remove the Linking to this User?',
    REMOVE_SOFT: 'Remove',
    REQUIRED: 'Required.',
    CREATED: 'Date of Production',
    CHANGED: 'Last changed',
    GATEWAY: 'Gateway',
    INTERFACE: 'Interface',
    OTHER: 'Other',
    INVALID: 'Invalid.',
    EDIT: 'Edit',
    USER: 'User',
    PROVISIONED: 'Provisioned',
    OPEN_USER: 'Open linked User',
    QR_CODE: 'QR Code',
    OPEN_QR_CODE: 'Open QR Code',
    OPEN_SHADOW: 'Open Shadow',
    CLOSE: 'Close',
    SET_VERIFICATION: 'Set Verification Flag',
    SET_VERIFICATION_COMPLETE: 'Verification Flag has been set',
    CONNECTION_STATE: 'Connection',
    STATE_CONNECTED_SINCE: 'Connected ',
    STATE_DISCONNECTED_SINCE: 'Disconnected ',
    STATE_UNKNOWN: 'No Data'
  },
  shadows: {
    SHADOWS: 'Provisioned Devices',
    REFRESH: 'Refresh',
    REMOVE_DEVICE: 'Remove Provisioning',
    CONFIRM_REMOVE:
      'Do you really want to remove the Provisioning of this Device?',
    REMOVE: 'Remove',
    CANCEL: 'Cancel',
    GATEWAYS: 'Gateway',
    INTERFACES: 'Interface',
    OTHERS: 'Other',
    SEARCH: 'Search'
  },
  shadow: {
    SHADOW: 'Shadow',
    REFRESH: 'Refresh',
    SAVE: 'Save',
    OPEN_PRODUCTION: 'Show in Production Table',
    OPEN_USER: 'Open linked User',
    SET_VERIFICATION: 'Set Verification Flag',
    REMOVE_LINK: 'Remove Link with User',
    REMOVE_LINK_SHORT: 'Remove Link',
    CONFIRM_REMOVE_LINK:
      'Do you really want to remove the Linking to this User?',
    REMOVE: 'Remove',
    CANCEL: 'Cancel',
    UNSAVED_CHANGES: 'Unsaved Changes',
    CONFIRM_UNSAVED_CHANGES: 'Do you really want to discard these changes?',
    DISCARD: 'Discard',
    OPEN_LOGS: 'Open Device Logs'
  },
  users: {
    USERS: 'All Users',
    REFRESH: 'Refresh',
    REMOVE_USER: 'Remove User',
    CONFIRM_REMOVE_USER: 'Do you really want to remove this User?',
    CANCEL: 'Cancel',
    REMOVE: 'Remove',
    REGULAR_USER: 'Regular User',
    SUPPORT: 'Support',
    ADMIN: 'Admin',
    INVITE_USERS: 'Invite User(s)',
    INVITE_SUCCESS: 'Successful: ',
    INVITE_RESEND: 'Reminded: ',
    INVITE_ERROR: 'Failed: '
  },
  invite: {
    INVITE_USERS: 'Invite User(s)',
    CANCEL: 'Cancel',
    INVITE: 'Invite',
    REQUIRED_FIELD: '* indicates required Field',
    SINGLE: 'Single',
    MULTIPLE: 'Multiple',
    EMAIL_LABEL: 'Email Address *',
    EMAIL_PLACEHOLDER: 'Enter your Email Address',
    FIRSTNAME_LABEL: 'First Name *',
    FIRSTNAME_PLACEHOLDER: 'Enter your First Name',
    LASTNAME_LABEL: 'Last Name *',
    LASTNAME_PLACEHOLDER: 'Enter your Last Name',
    FILE_LABEL: 'File *',
    FILE_PLACEHOLDER: 'Select Excel File',
    SHEET_LABEL: 'Sheet *',
    SHEET_PLACEHOLDER: 'Select Sheet',
    FIRSTNAME_COLUMN_LABEL: 'Column for Firstname *',
    FIRSTNAME_COLUMN_PLACEHOLDER: 'Select Column',
    LASTNAME_COLUMN_LABEL: 'Column for Lastname *',
    LASTNAME_COLUMN_PLACEHOLDER: 'Select Column',
    EMAIL_COLUMN_LABEL: 'Column for Email Address *',
    EMAIL_COLUMN_PLACEHOLDER: 'Select Column',
    REQUIRED: 'Required.',
    TOO_LONG: 'Too lang.',
    TOO_LARGE: 'Too large.',
    INVALID_MAIL_ADDRESS: 'Invalid Mail Address.',
    PARSE_ERROR: 'Could not parse XLSX File!'
  },
  user: {
    COGNITO_USER: 'Cognito User',
    OPEN_PRODUCTION: 'Lookup linked devices',
    REFRESH: 'Refresh',
    PROPERTY: 'Property',
    VALUE: 'Value',
    GLOBAL_SIGNOUT: 'Invalidate all Tokens',
    CONFIRM_GLOBAL_SIGNOUT:
      'Do you really want to invalidate all Tokens for this User?',
    SIGNOUT: 'Invalidate',
    CANCEL: 'Cancel',
    REGULAR_USER: 'Regular User',
    SUPPORT: 'Support',
    ADMIN: 'Admin'
  },
  logs: {
    STROMEE_PLUS_LOG_GROUPS: 'Stromee Plus Logs',
    STROMEE_LOG_GROUPS: 'Stromee Logs',
    DEVICE_LOGS: 'Devices',
    PROVISIONING_LOGS: 'Provisioning',
    IOT_LOGS: 'AWS IoT Core',
    API_LOGS: 'Stromee Plus API',
    FLAG_LOGS: 'Verification Flag',
    HISTORY_LOGS: 'History Data',
    CONNECTION_LOGS: 'Connection State',
    SIGNUP_LOGS: 'Cognito Signup',
    MIGRATION_LOGS: 'Cognito Migration',
    CUSTOMER_API_LOGS: 'Customer API',
    EMAIL_LOGS: 'Email Sender'
  },
  log: {
    TABLE_HEADER: 'Logs',
    SEARCH: 'Search',
    REFRESH: 'Refresh',
    OPEN_CLOUDWATCH: 'Open in CloudWatch',
    TIMESTAMP: 'Time',
    MESSAGE: 'Message',
    TIMESPAN: 'Timespan:'
  },
  ota: {
    OTA: 'OTA Updates',
    FIRMWARE: 'Firmware Versions',
    UPDATES: 'Update Jobs'
  },
  jobs: {
    TABLE_HEADER: 'Update Jobs',
    SEARCH: 'Search',
    ADD_JOB: 'Create Job',
    REFRESH: 'Refresh',
    OPEN_FIRMWARE: 'Go to Firmware',
    OPEN_DEVICE: 'Go to target',
    OPEN_DEVICES: 'Go to targets',
    ALL_GATEWAYS: 'All Gateways',
    ALL_INTERFACES: 'All Interfaces',
    ALL_OTHERS: 'All Others',
    ALL_DEVICES: 'All Devices',
    REMOVE_JOB: 'Remove Job',
    REQUIRED_FIELD: '* indicates required Field',
    CANCEL: 'Cancel',
    ADD: 'Create',
    CONFIRM_REMOVE: 'Do you really want to remove this Update Job?',
    REMOVE: 'Remove',
    UNIQUE: 'Has to be unique.',
    REQUIRED: 'Required.',
    INVALID: 'Invalid.',
    NAME: 'Name',
    FIRMWARE: 'Firmware',
    STRATEGY: 'Target Selection',
    TARGETS: 'Targets',
    CREATED: 'Creation Date',
    CONTINUOUS: 'Continuous',
    SNAPSHOT: 'Snapshot',
    NAME_REQUIRED: 'Name *',
    FIRMWARE_REQUIRED: 'Firmware *',
    VERSION_REQUIRED: 'Version *',
    STRATEGY_REQUIRED: 'Target Selection *',
    TARGETS_REQUIRED: 'Targets *',
    GROUPS: 'Groups',
    GATEWAYS: 'Gateways',
    INTERFACES: 'Interfaces',
    OTHERS: 'Others',
    SHOW_STATUS: 'Show Update Status',
    UPDATE_STATUS: 'Update Status',
    CLOSE: 'Schließen'
  },
  firmware: {
    TABLE_HEADER: 'Firmware Versions',
    SEARCH: 'Search',
    ADD_FIRMWARE: 'Add Firmware',
    REFRESH: 'Refresh',
    REMOVE_FIRMWARE: 'Remove Firmware',
    NAME_REQUIRED: 'Name *',
    FILE_REQUIRED: 'Firmware File *',
    REQUIRED_FIELD: '* indicates required Field',
    CANCEL: 'Cancel',
    ADD: 'Add',
    CONFIRM_REMOVE: 'Do you really want to remove this Firmware Version?',
    REMOVE: 'Remove',
    REQUIRED: 'Required.',
    TOO_LARGE: 'Too large.',
    NAME: 'Name',
    SIZE: 'File Size',
    MODIFIED: 'Modification Date',
    OPEN_JOB: 'Search related Update Jobs',
    DOWNLOAD_FORMWARE: 'Download Firmware'
  },
  meter: {
    TEMPLATE: 'Config Template',
    TEMPLATE_REQUIRED: 'Config Template *',
    BASIC: 'Hex String',
    EXPERT: 'Parameters',
    BASIC_CONFIG: 'Interface Config *',
    DATA_CONFIG: 'OBIS-Codes *',
    TYPE_REQUIRED: 'Interface Type *',
    BAUD_REQUIRED: 'Baud Rate *',
    PARITY_REQUIRED: 'Parity *',
    STOPBITS_REQUIRED: 'Stopbits *',
    LENGTH_REQUIRED: 'Data Length *',
    INVERSION_REQUIRED: 'Polarity Inversion *',
    MODE_REQUIRED: 'Operation Mode *',
    INTERVAL_REQUIRED: 'Interval (in Seconds) *',
    PROTOCOL_REQUIRED: 'Protocol *',
    SECURITY_REQUIRED: 'Encryption *',
    KEY1_REQUIRED: 'Global Authentication Key (GAK) *',
    KEY2_REQUIRED: 'Global Unicast Encryption Key (GUEK) *',
    REQUIRED: 'Required.',
    TOO_MANY: 'Too many.',
    TOO_LONG: 'Too lang.',
    INVALID: 'Invalid.'
  },
  executions: {
    RETRY_EXECUTION: 'Retry Update for selected Device',
    RETRY_EXECUTIONS: 'Retry Update for selected Devices',
    CANCEL_EXECUTION: 'Cancel Update for selected Device',
    CANCEL_EXECUTIONS: 'Cancel Update for selected Devices',
    SEARCH: 'Search',
    REFRESH: 'Refresh',
    UID: 'UID',
    STATUS: 'Status',
    CANCELED: 'Canceled',
    FAILED: 'Failed',
    IN_PROGRESS: 'In Progress',
    QUEUED: 'Queued',
    REJECTED: 'Rejected',
    REMOVED: 'Removed',
    SUCCEEDED: 'Successful',
    TIMED_OUT: 'Timeout',
    OPEN_SHADOW: 'Open Shadow'
  }
};
