import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import type {
  CognitoUserSession,
  ISignUpResult
} from 'amazon-cognito-identity-js';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  DeviceType,
  DeviceShadow,
  UpdateStatus,
  Measurement,
  MeterTemplate,
  ConnectionState
} from '../typings';
import _ from 'lodash';
import {
  AMPLIFY_CONFIG,
  STROMEE_CUSTOMER_API_DOMAIN,
  STROMEE_PLUS_API_DOMAIN
} from '../constants';

export enum ConfirmType {
  register = 'register',
  email = 'email',
  invite = 'invite',
  password = 'password'
}

const stromeePlusDefaultConfig: AxiosRequestConfig = {
  baseURL: `https://${STROMEE_PLUS_API_DOMAIN}`,
  responseType: 'json'
};

const stromeeCustomerDefaultConfig: AxiosRequestConfig = {
  baseURL: `https://${STROMEE_CUSTOMER_API_DOMAIN}`,
  responseType: 'json'
};

export function login(username: string, password: string): Promise<void> {
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST ${
      AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/[^/]+(\/?.*)$/, '$1') ||
      '/'
    } HTTP/2
Host: ${AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/([^/]+).*$/, '$1')}
Content-Type: application/x-amz-json-1.1
X-Amz-Target: AWSCognitoIdentityProviderService.InitiateAuth

${JSON.stringify(
  {
    AuthFlow: AMPLIFY_CONFIG.Auth.authenticationFlowType,
    ClientId: AMPLIFY_CONFIG.Auth.userPoolWebClientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password
    },
    ClientMetadata: {}
  },
  undefined,
  2
)}`
  });
  return Auth.signIn({
    username,
    password
  })
    .then(
      ({
        signInUserSession
      }: {
        signInUserSession: CognitoUserSession;
      }): void => {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 200 OK
Content-Type: application/x-amz-json-1.1

${JSON.stringify(
  {
    AuthenticationResult: {
      AccessToken: signInUserSession.getAccessToken().getJwtToken(),
      ExpiresIn: 3600,
      IdToken: signInUserSession.getIdToken().getJwtToken(),
      RefreshToken: signInUserSession.getRefreshToken().getToken(),
      TokenType: 'Bearer'
    },
    ChallengeParameters: {}
  },
  undefined,
  2
)}`
        });
      }
    )
    .catch((error: { code: string; message: string; name: string }): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 400 Bad Request
Content-Type: application/x-amz-json-1.1
X-Amzn-ErrorType: ${error.code}:
X-Amzn-ErrorMessage: ${error.message}

${JSON.stringify(
  {
    __type: error.code,
    message: error.message
  },
  undefined,
  2
)}`
      });
      throw new Error(error.message);
    });
}

export function resend(Username: string): Promise<void> {
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST ${
      AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/[^/]+(\/?.*)$/, '$1') ||
      '/'
    } HTTP/2
Host: ${AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/([^/]+).*$/, '$1')}
Content-Type: application/x-amz-json-1.1
X-Amz-Target: AWSCognitoIdentityProviderService.ResendConfirmationCode

${JSON.stringify(
  {
    ClientId: AMPLIFY_CONFIG.Auth.userPoolWebClientId,
    Username,
    ClientMetadata: {}
  },
  undefined,
  2
)}`
  });
  return Auth.resendSignUp(Username)
    .then((result: unknown): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/x-amz-json-1.1

${JSON.stringify(result, undefined, 2)}`
      });
    })
    .catch((error: { code: string; message: string; name: string }): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 400 Bad Request
Content-Type: application/x-amz-json-1.1
X-Amzn-ErrorType: ${error.code}:
X-Amzn-ErrorMessage: ${error.message}

${JSON.stringify(
  {
    __type: error.code,
    message: error.message
  },
  undefined,
  2
)}`
      });
      throw new Error(error.message);
    });
}

export function resetPassword(Username: string): Promise<void> {
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST ${
      AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/[^/]+(\/?.*)$/, '$1') ||
      '/'
    } HTTP/2
Host: ${AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/([^/]+).*$/, '$1')}
Content-Type: application/x-amz-json-1.1
X-Amz-Target: AWSCognitoIdentityProviderService.ForgotPassword

${JSON.stringify(
  {
    ClientId: AMPLIFY_CONFIG.Auth.userPoolWebClientId,
    Username,
    ClientMetadata: {}
  },
  undefined,
  2
)}`
  });
  return Auth.forgotPassword(Username)
    .then((result: unknown): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/x-amz-json-1.1

${JSON.stringify(result, undefined, 2)}`
      });
    })
    .catch((error: { code: string; message: string; name: string }): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 400 Bad Request
Content-Type: application/x-amz-json-1.1
X-Amzn-ErrorType: ${error.code}:
X-Amzn-ErrorMessage: ${error.message}

${JSON.stringify(
  {
    __type: error.code,
    message: error.message
  },
  undefined,
  2
)}`
      });
      throw new Error(error.message);
    });
}

export async function logout(): Promise<void> {
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST ${
      AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/[^/]+(\/?.*)$/, '$1') ||
      '/'
    } HTTP/2
Host: ${AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/([^/]+).*$/, '$1')}
Content-Type: application/x-amz-json-1.1
X-Amz-Target: AWSCognitoIdentityProviderService.RevokeToken

${JSON.stringify(
  {
    ClientId: AMPLIFY_CONFIG.Auth.userPoolWebClientId,
    Token: (await Auth.currentSession()).getRefreshToken().getToken()
  },
  undefined,
  2
)}`
  });
  return Auth.signOut()
    .then((): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK`
      });
    })
    .catch((error: { code: string; message: string; name: string }): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 400 Bad Request
Content-Type: application/x-amz-json-1.1
X-Amzn-ErrorType: ${error.code}:
X-Amzn-ErrorMessage: ${error.message}

${JSON.stringify(
  {
    __type: error.code,
    message: error.message
  },
  undefined,
  2
)}`
      });
      throw new Error(error.message);
    });
}

export function register(
  username: string,
  password: string,
  firstname: string,
  lastname: string,
  locale: string,
  phone_number?: string
): Promise<void> {
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST ${
      AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/[^/]+(\/?.*)$/, '$1') ||
      '/'
    } HTTP/2
Host: ${AMPLIFY_CONFIG.Auth.endpoint.replace(/^https?:\/\/([^/]+).*$/, '$1')}
Content-Type: application/x-amz-json-1.1
X-Amz-Target: AWSCognitoIdentityProviderService.SignUp

${JSON.stringify(
  {
    ClientId: AMPLIFY_CONFIG.Auth.userPoolWebClientId,
    Username: username,
    Password: password,
    UserAttributes: phone_number
      ? [
          {
            Name: 'phone_number',
            Value: phone_number
          }
        ]
      : [],
    ValidationData: [
      {
        Name: 'firstname',
        Value: firstname
      },
      {
        Name: 'lastname',
        Value: lastname
      },
      {
        Name: 'locale',
        Value: locale
      },
      {
        Name: 'createdAs',
        Value: 'cognito'
      }
    ]
  },
  undefined,
  2
)}`
  });
  return Auth.signUp({
    username,
    password,
    attributes: phone_number ? { phone_number } : undefined,
    validationData: {
      firstname,
      lastname,
      locale
    }
  })
    .then((res: ISignUpResult): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/x-amz-json-1.1

${JSON.stringify(res, undefined, 2)}`
      });
    })
    .catch((error: { code: string; message: string; name: string }): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 400 Bad Request
Content-Type: application/x-amz-json-1.1
X-Amzn-ErrorType: ${error.code}:
X-Amzn-ErrorMessage: ${error.message}

${JSON.stringify(
  {
    __type: error.code,
    message: error.message
  },
  undefined,
  2
)}`
      });
      throw new Error(error.message);
    });
}

export async function confirm(
  confirm: ConfirmType,
  id: string,
  token: string,
  password?: string
): Promise<string[]> {
  const data: Record<string, unknown> = {
    id,
    token,
    password
  };
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST /v1/auth/confirm/${confirm} HTTP/2
Host: ${STROMEE_CUSTOMER_API_DOMAIN}
Content-Type: application/json

${JSON.stringify(data, undefined, 2)}`
  });
  return axios({
    ...stromeeCustomerDefaultConfig,
    method: 'POST',
    url: `/v1/auth/confirm/${confirm}`,
    data
  })
    .then((res: AxiosResponse<string[]>): string[] => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): string[] => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getDeviceList(deviceType: DeviceType): Promise<string[]> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /${deviceType} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: `/${deviceType}`,
    headers: {
      authorization
    }
  })
    .then((res: AxiosResponse<string[]>): string[] => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): string[] => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function addDevice(
  deviceType: DeviceType,
  uid: string,
  verification: string
): Promise<void> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  const data: { verification: string } = {
    verification
  };
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST /${deviceType}/${uid} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}
Content-Type: application/json

${JSON.stringify(data, undefined, 2)}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'POST',
    url: `/${deviceType}/${uid}`,
    headers: {
      authorization
    },
    data
  })
    .then((): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 201 OK`
      });
    })
    .catch((error: AxiosError<{ message: string }>): void => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function removeDevice(
  deviceType: DeviceType,
  uid: string
): Promise<void> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `DELETE /${deviceType}/${uid} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'DELETE',
    url: `/${deviceType}/${uid}`,
    headers: {
      authorization
    }
  })
    .then((): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK`
      });
    })
    .catch((error: AxiosError<{ message: string }>): void => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getDevice(
  deviceType: DeviceType,
  uid: string
): Promise<DeviceShadow> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /${deviceType}/${uid} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: `/${deviceType}/${uid}`,
    headers: {
      authorization
    }
  })
    .then((res: AxiosResponse<DeviceShadow>): DeviceShadow => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): DeviceShadow => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function updateDeviceProperty(
  deviceType: DeviceType,
  uid: string,
  path: string,
  value: unknown
): Promise<void> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  let data: Record<string, unknown> = _.set({}, path, value);
  if (!path) {
    data = value as Record<string, unknown>;
  }
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `PUT /${deviceType}/${uid} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}
Content-Type: application/json

${JSON.stringify(data, undefined, 2)}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'PUT',
    url: `/${deviceType}/${uid}`,
    headers: {
      authorization
    },
    data
  })
    .then((): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK`
      });
    })
    .catch((error: AxiosError<{ message: string }>): void => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getLoraDeviceList(uid: string): Promise<string[]> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /gateways/${uid}/interfaces HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: `/gateways/${uid}/interfaces`,
    headers: {
      authorization
    }
  })
    .then((res: AxiosResponse<string[]>): string[] => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): string[] => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function addLoraDevice(
  gatewayUid: string,
  interfaceUid: string,
  verification: string,
  basic_config_data: string,
  data_config_data: string,
  revolutions_per_kilo_watt_hour?: number
): Promise<void> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  const data: {
    verification: string;
    basic_config_data: string;
    data_config_data: string;
    revolutions_per_kilo_watt_hour?: number;
  } = {
    verification,
    basic_config_data,
    data_config_data,
    revolutions_per_kilo_watt_hour
  };
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `POST /gateways/${gatewayUid}/interfaces/${interfaceUid} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}
Content-Type: application/json

${JSON.stringify(data, undefined, 2)}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'POST',
    url: `/gateways/${gatewayUid}/interfaces/${interfaceUid}`,
    headers: {
      authorization
    },
    data
  })
    .then((): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 201 OK`
      });
    })
    .catch((error: AxiosError<{ message: string }>): void => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function removeLoraDevice(
  gatewayUid: string,
  interfaceUid: string
): Promise<void> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `DELETE /gateways/${gatewayUid}/interfaces/${interfaceUid} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'DELETE',
    url: `/gateways/${gatewayUid}/interfaces/${interfaceUid}`,
    headers: {
      authorization
    }
  })
    .then((): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK`
      });
    })
    .catch((error: AxiosError<{ message: string }>): void => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getLoraDevice(
  gatewayUid: string,
  interfaceUid: string
): Promise<DeviceShadow> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /gateways/${gatewayUid}/interfaces/${interfaceUid} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: `/gateways/${gatewayUid}/interfaces/${interfaceUid}`,
    headers: {
      authorization
    }
  })
    .then((res: AxiosResponse<DeviceShadow>): DeviceShadow => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): DeviceShadow => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function updateLoraDeviceProperty(
  gatewayUid: string,
  interfaceUid: string,
  path: string,
  value: unknown
): Promise<void> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  let data: Record<string, unknown> = _.set({}, path, value);
  if (!path) {
    data = value as Record<string, unknown>;
  }
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `PUT /gateways/${gatewayUid}/interfaces/${interfaceUid} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}
Content-Type: application/json

${JSON.stringify(data, undefined, 2)}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'PUT',
    url: `/gateways/${gatewayUid}/interfaces/${interfaceUid}`,
    headers: {
      authorization
    },
    data
  })
    .then((): void => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK`
      });
    })
    .catch((error: AxiosError<{ message: string }>): void => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getUpdateProgress(
  deviceType: DeviceType,
  uid: string
): Promise<UpdateStatus> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /${deviceType}/${uid}/updates/progress HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: `/${deviceType}/${uid}/updates/progress`,
    headers: {
      authorization
    }
  })
    .then((res: AxiosResponse<UpdateStatus>): UpdateStatus => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): UpdateStatus => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getConnectionState(
  deviceType: DeviceType,
  uid: string
): Promise<ConnectionState | undefined> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /${deviceType}/${uid}/connection-state HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: `/${deviceType}/${uid}/connection-state`,
    headers: {
      authorization
    }
  })
    .then(
      (
        res: AxiosResponse<ConnectionState | undefined>
      ): ConnectionState | undefined => {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
        });
        return res.data;
      }
    )
    .catch(
      (error: AxiosError<{ message: string }>): ConnectionState | undefined => {
        if (error.response) {
          Hub.dispatch('appConsole', {
            event: 'response',
            data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
          });
        } else {
          Hub.dispatch('appConsole', {
            event: 'response',
            data: 'CORS Error'
          });
        }
        throw new Error(error.message);
      }
    );
}

export async function getAvailableHistoryMeasurements(
  uid: string
): Promise<string[]> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /history/devices/${uid}/measurements HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: `/history/devices/${uid}/measurements`,
    headers: {
      authorization
    }
  })
    .then((res: AxiosResponse<string[]>): string[] => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): string[] => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getMeasurements(
  version?: string
): Promise<Record<string, Measurement>> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /measurement-meta-data${
      version ? '?version=' + version : ''
    } HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: '/measurement-meta-data',
    headers: {
      authorization
    },
    params: version ? { version } : undefined
  })
    .then(
      (
        res: AxiosResponse<Record<string, Measurement>>
      ): Record<string, Measurement> => {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
        });
        return res.data;
      }
    )
    .catch(
      (error: AxiosError<{ message: string }>): Record<string, Measurement> => {
        if (error.response) {
          Hub.dispatch('appConsole', {
            event: 'response',
            data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
          });
        } else {
          Hub.dispatch('appConsole', {
            event: 'response',
            data: 'CORS Error'
          });
        }
        throw new Error(error.message);
      }
    );
}

export async function getMeterTemplates(
  version?: string
): Promise<MeterTemplate[]> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /meter-templates${version ? '?version=' + version : ''} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: '/meter-templates',
    headers: {
      authorization
    },
    params: version ? { version } : undefined
  })
    .then((res: AxiosResponse<MeterTemplate[]>): MeterTemplate[] => {
      Hub.dispatch('appConsole', {
        event: 'response',
        data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
      });
      return res.data;
    })
    .catch((error: AxiosError<{ message: string }>): MeterTemplate[] => {
      if (error.response) {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
        });
      } else {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: 'CORS Error'
        });
      }
      throw new Error(error.message);
    });
}

export async function getHistoryData(
  uid: string,
  measurement: string,
  start: number | string = '-1d',
  stop: number | string = 'now()',
  aggregate?: string
): Promise<Record<number, unknown>> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `GET /history/devices/${uid}/measurements/${measurement}?start=${start}&stop=${stop}${
      aggregate ? `&aggregate=${aggregate}` : ''
    } HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'GET',
    url: `/history/devices/${uid}/measurements/${measurement}`,
    headers: {
      authorization
    },
    params: {
      start,
      stop,
      aggregate
    }
  })
    .then(
      (
        res: AxiosResponse<Record<number, unknown>>
      ): Record<number, unknown> => {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
        });
        return res.data;
      }
    )
    .catch(
      (error: AxiosError<{ message: string }>): Record<number, unknown> => {
        if (error.response) {
          Hub.dispatch('appConsole', {
            event: 'response',
            data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
          });
        } else {
          Hub.dispatch('appConsole', {
            event: 'response',
            data: 'CORS Error'
          });
        }
        throw new Error(error.message);
      }
    );
}

export async function deleteHistoryData(
  uid?: string,
  measurement?: string,
  start: number | string = 0,
  stop: number | string = Date.now()
): Promise<Record<number, unknown>> {
  const authorization: string = `Bearer ${(await Auth.currentSession())
    .getIdToken()
    .getJwtToken()}`;
  Hub.dispatch('appConsole', {
    event: 'request',
    data: `DELETE /history${uid ? `/devices/${uid}` : ''}${
      uid && measurement ? `/measurements/${measurement}` : ''
    }?start=${start}&stop=${stop} HTTP/2
Host: ${STROMEE_PLUS_API_DOMAIN}
Authorization: ${authorization}`
  });
  return axios({
    ...stromeePlusDefaultConfig,
    method: 'DELETE',
    url: `/history${uid ? `/devices/${uid}` : ''}${
      uid && measurement ? `/measurements/${measurement}` : ''
    }`,
    headers: {
      authorization
    },
    params: {
      start,
      stop
    }
  })
    .then(
      (
        res: AxiosResponse<Record<number, unknown>>
      ): Record<number, unknown> => {
        Hub.dispatch('appConsole', {
          event: 'response',
          data: `HTTP/2 200 OK
Content-Type: application/json

${JSON.stringify(res.data, undefined, 2)}`
        });
        return res.data;
      }
    )
    .catch(
      (error: AxiosError<{ message: string }>): Record<number, unknown> => {
        if (error.response) {
          Hub.dispatch('appConsole', {
            event: 'response',
            data: `HTTP/2 ${error.response.status} ${error.response.statusText}
Content-Type: application/json

${JSON.stringify(error.response.data, undefined, 2)}`
          });
        } else {
          Hub.dispatch('appConsole', {
            event: 'response',
            data: 'CORS Error'
          });
        }
        throw new Error(error.message);
      }
    );
}
