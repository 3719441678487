import type { JobExecutionSummary } from '@aws-sdk/client-iot';
import type { JsonObject, JsonValue } from 'type-fest';
import type { IBasicConfig } from './basicConfig';

export enum StromeePlusLogGroup {
  DEVICES = 'devices',
  PROVISIONING = 'provisioning',
  IOT = 'iot',
  API = 'api',
  FLAG = 'flag',
  HISTORY = 'history',
  CONNECTION = 'connection'
}

export enum StromeeLogGroup {
  CUSTOMER_API = 'customer_api',
  SIGNUP = 'signup',
  MIGRATION = 'migration',
  EMAIL = 'email'
}

export enum DeviceType {
  GATEWAY = 'gateways',
  INTERFACE = 'interfaces',
  OTHER = 'others'
}

export enum DeviceType_Singular {
  GATEWAY = 'gateway',
  INTERFACE = 'interface',
  OTHER = 'other'
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export interface ProducedDevice {
  uid: string;
  type: DeviceType_Singular;
  verification: string;
  created: number;
  connected?: boolean;
  lastConnectAt?: number;
  lastDisconnectAt?: number;
  changedAt?: number;
  changedBy?: string;
  sub?: string;
  provisioned?: boolean;
}

export interface DeviceLink {
  uid: string;
  sub: string;
  type: DeviceType_Singular;
}

interface MetadataHelper<T> {
  [key: string]: T;
}
export type Metadata = MetadataHelper<Metadata | number | undefined>;

export interface DeviceState {
  temperature_offset?: number;
  humidity_offset?: number;
  interfaces?: Record<string, Record<string, JsonValue | undefined>>;
  meter_configuration?: {
    basic_config_data?: string;
    data_config_data?: string;
    revolutions_per_kilo_watt_hour?: number;
  };
  measurements?: Record<string, JsonValue>;
  [key: string]: JsonValue | undefined;
}

export interface DeviceStateMetadata {
  temperature_offset?: number;
  humidity_offset?: number;
  interfaces?: Record<string, Metadata>;
  meter_configuration?: {
    basic_config_data?: number;
    data_config_data?: number;
  };
  measurements?: Record<string, Metadata>;
  [key: string]: Metadata | number | undefined;
}

export interface DeviceShadow {
  state: {
    desired: DeviceState;
    reported: DeviceState;
    delta?: unknown;
  };
  metadata: {
    desired: DeviceStateMetadata;
    reported: DeviceStateMetadata;
  };
  version?: number;
  timestamp: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationRule = (value: any) => boolean | string;
export type ValidationRules = ValidationRule[];

export interface LogEntry {
  timestamp: number;
  message: Array<string | JsonObject>;
  link: string;
}

export interface CustomJobExecution extends JobExecutionSummary {
  arn: string;
  uid: string;
}

export interface UpdateStatus {
  version: string;
  step: 'DOWNLOAD' | 'SELF_TEST';
  downloadProgress?: string;
}

export interface Measurement {
  unit?: string;
  values?: unknown[] | { min?: number; max?: number };
  description: Record<string, string>;
}

export interface MeterTemplate {
  name: string;
  basic: IBasicConfig;
  data: string[];
}

export interface CognitoUser {
  sub: string;
  email: string;
  userGroup?: string;
}

export interface UserInvitation {
  firstname: string;
  lastname: string;
  email: string;
}

export type ConfirmType = 'email' | 'register' | 'invite' | 'password';

export interface Confirm {
  token: string;
  data?: unknown;
  timestamp: number;
}

export interface Customer {
  id: string;
  sub: string;
  email: string;
  confirm?: Record<ConfirmType, Confirm>;
  firstname: string;
  lastname: string;
  locale?: string;
  createdAt: number;
  createdAs: string;
  freshChatCustomerId: string;
  usedPromoCodes: string[];
  enableMeterReadingReminders: boolean;
  active: boolean; //entfällt ggf.
  activationMailCount: number; //entfällt ggf.
  welcomeEmail: boolean; //entfällt ggf.
}

export interface ConnectionState {
  lastConnectAt: number;
  lastDisconnectAt: number;
  state: boolean;
}
