












































































import Auth from '@aws-amplify/auth';
import {
  mdiAccount,
  mdiAccountGroup,
  mdiCarShiftPattern,
  mdiChartLine,
  mdiCloudDownload,
  mdiFactory,
  mdiGhost,
  mdiPaperRoll,
  mdiRadioTower
} from '@mdi/js';
import { ADMIN_USER_GROUP, SUPPORT_USER_GROUP } from '../constants';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HomeView extends Vue {
  private readonly ADMIN_USER_GROUP: typeof ADMIN_USER_GROUP =
    ADMIN_USER_GROUP;
  private readonly SUPPORT_USER_GROUP: typeof SUPPORT_USER_GROUP =
    SUPPORT_USER_GROUP;
  private readonly userItems: Array<{
    text: string;
    icon: string;
    link: string;
  }> = [
    {
      text: '$vuetify.home.ACCOUNT',
      icon: mdiAccount,
      link: '/account'
    },
    {
      text: '$vuetify.home.GATEWAYS',
      icon: mdiCarShiftPattern,
      link: '/gateways'
    },
    {
      text: '$vuetify.home.INTERFACES',
      icon: mdiRadioTower,
      link: '/interfaces'
    }
  ];
  private readonly adminItems: Array<{
    text: string;
    icon: string;
    link: string;
    external?: true;
    groups: string[];
  }> = [
    {
      text: '$vuetify.home.USERS',
      icon: mdiAccountGroup,
      link: '/users',
      groups: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
    },
    {
      text: '$vuetify.home.ALL_DEVICES',
      icon: mdiFactory,
      link: '/production',
      groups: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
    },
    {
      text: '$vuetify.home.SHADOWS',
      icon: mdiGhost,
      link: '/shadows',
      groups: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
    },
    {
      text: '$vuetify.home.HISTORY',
      icon: mdiChartLine,
      link: 'https://eu-central-1-1.aws.cloud2.influxdata.com/orgs/a724e588e99e4b3e/data-explorer',
      external: true,
      groups: [ADMIN_USER_GROUP]
    },
    {
      text: '$vuetify.home.OTA',
      icon: mdiCloudDownload,
      link: '/ota',
      groups: [ADMIN_USER_GROUP]
    },
    {
      text: '$vuetify.home.LOGS',
      icon: mdiPaperRoll,
      link: '/logs',
      groups: [ADMIN_USER_GROUP, SUPPORT_USER_GROUP]
    }
  ];

  private userGroups: string[] = [];

  private get filteredAdminItems(): Array<{
    text: string;
    icon: string;
    link: string;
    external?: true;
    groups: string[];
  }> {
    return this.adminItems.filter(
      (item: {
        text: string;
        icon: string;
        link: string;
        external?: true;
        groups: string[];
      }): boolean =>
        item.groups.some((group: string): boolean =>
          this.userGroups.includes(group)
        )
    );
  }

  private async created(): Promise<void> {
    this.userGroups =
      (
        await Auth.currentSession().catch((): undefined => undefined)
      )?.getIdToken()?.payload?.['cognito:groups'] || [];
  }
}
