var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{
    'mr-2': _vm.$vuetify.breakpoint.mdAndUp
  },attrs:{"fluid":"","fill-height":_vm.$vuetify.breakpoint.mdAndUp}},[_c('v-row',{class:{
      'flat-background-sm': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"justify":"center"}},[_c('v-card',{staticClass:"pa-6 ma-3 mr-1",staticStyle:{"max-height":"100vh","overflow-y":"auto","overflow-x":"hidden"},attrs:{"width":"500","flat":_vm.$vuetify.breakpoint.smAndDown}},[(_vm.currentAuthState === _vm.AuthState.Login)?[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.LOGIN_HEADER'))+" ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"type":"email","name":"email","label":_vm.$vuetify.lang.t('$vuetify.auth.EMAIL_LABEL'),"placeholder":_vm.$vuetify.lang.t('$vuetify.auth.EMAIL_PLACEHOLDER'),"rules":[_vm.requiredRule ].concat( _vm.emailRules),"pattern":_vm.emailPattern,"counter":"320","maxlength":"320","required":"","disabled":_vm.loading,"autofocus":"","autocomplete":"email"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"type":"password","name":"password","label":_vm.$vuetify.lang.t('$vuetify.auth.PASSWORD_LABEL'),"placeholder":_vm.$vuetify.lang.t('$vuetify.auth.PASSWORD_PLACEHOLDER'),"rules":[_vm.requiredRule ].concat( _vm.passwordRules),"counter":"99","maxlength":"99","minlength":"8","required":"","disabled":_vm.loading,"autocomplete":"current-password"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.FORGOT_PASSWORD'))+" ")]),_c('a',{staticClass:"mr-6",on:{"click":function($event){_vm.currentAuthState = _vm.AuthState.Reset}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.RESET_PASSWORD'))+" ")])],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.NO_ACCOUNT'))+" ")]),_c('a',{staticClass:"mr-6",on:{"click":function($event){_vm.currentAuthState = _vm.AuthState.Register}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.REGISTER'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading || !_vm.valid,"loading":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.LOGIN_BUTTON'))+" ")])],1)],1)]:(_vm.currentAuthState === _vm.AuthState.Register)?[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.REGISTER_HEADER'))+" ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"name":"firstname","label":_vm.$vuetify.lang.t('$vuetify.auth.FIRSTNAME_LABEL'),"placeholder":_vm.$vuetify.lang.t('$vuetify.auth.FIRSTNAME_PLACEHOLDER'),"rules":[_vm.requiredRule],"required":"","autofocus":"","disabled":_vm.loading,"autocomplete":"given-name"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('v-text-field',{attrs:{"name":"lastname","label":_vm.$vuetify.lang.t('$vuetify.auth.LASTNAME_LABEL'),"placeholder":_vm.$vuetify.lang.t('$vuetify.auth.LASTNAME_PLACEHOLDER'),"rules":[_vm.requiredRule],"required":"","disabled":_vm.loading,"autocomplete":"family-name"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('v-text-field',{attrs:{"type":"email","name":"email","label":_vm.$vuetify.lang.t('$vuetify.auth.EMAIL_LABEL'),"placeholder":_vm.$vuetify.lang.t('$vuetify.auth.EMAIL_PLACEHOLDER'),"rules":[_vm.requiredRule ].concat( _vm.emailRules, [_vm.domainRule]),"pattern":_vm.emailPattern,"counter":"320","maxlength":"320","required":"","disabled":_vm.loading,"autocomplete":"email"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"type":"password","name":"password","label":_vm.$vuetify.lang.t('$vuetify.auth.PASSWORD_LABEL'),"placeholder":_vm.$vuetify.lang.t('$vuetify.auth.PASSWORD_A_PLACEHOLDER'),"rules":[_vm.requiredRule ].concat( _vm.passwordRules),"counter":"99","maxlength":"99","minlength":"8","required":"","disabled":_vm.loading,"autocomplete":"new-password"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.HAVE_ACCOUNT'))+" ")]),_c('a',{staticClass:"mr-6",on:{"click":function($event){_vm.currentAuthState = _vm.AuthState.Login}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.LOGIN'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading || !_vm.valid,"loading":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.REGISTER_BUTTON'))+" ")])],1)],1)]:(_vm.currentAuthState === _vm.AuthState.Reset)?[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.RESET_PASSWORD_HEADER'))+" ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"type":"email","name":"email","label":_vm.$vuetify.lang.t('$vuetify.auth.EMAIL_LABEL'),"placeholder":_vm.$vuetify.lang.t('$vuetify.auth.EMAIL_PLACEHOLDER'),"rules":[_vm.requiredRule ].concat( _vm.emailRules, [_vm.domainRule]),"pattern":_vm.emailPattern,"counter":"320","maxlength":"320","required":"","autofocus":"","disabled":_vm.loading,"autocomplete":"email"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
    var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('a',{staticClass:"mr-6",on:{"click":function($event){_vm.currentAuthState = _vm.AuthState.Login}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.BACK_TO_SIGN_IN'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading || !_vm.valid,"loading":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.SEND_LINK'))+" ")])],1)],1)]:(_vm.currentAuthState === _vm.AuthState.Confirm)?[(!_vm.confirmType)?[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.CONFIRM_HEADER'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.CHECK_YOUR_EMAIL'))+" ")])]:(
            [_vm.ConfirmType.register, _vm.ConfirmType.email].includes(_vm.confirmType)
          )?[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.CONFIRM_HEADER'))+" ")]),_c('v-card-text',[(_vm.loading)?_c('v-progress-circular',{staticClass:"mt-4",staticStyle:{"left":"50%","transform":"translate(-50%)"},attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)]:[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.PASSWORD_HEADER'))+" ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.confirm.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"value":_vm.email,"type":"email","name":"email","label":_vm.$vuetify.lang.t('$vuetify.auth.EMAIL_LABEL'),"disabled":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
          var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}])}),_c('v-text-field',{attrs:{"type":"password","name":"password","label":_vm.$vuetify.lang.t('$vuetify.auth.PASSWORD_LABEL'),"placeholder":_vm.$vuetify.lang.t('$vuetify.auth.PASSWORD_A_PLACEHOLDER'),"rules":[_vm.requiredRule ].concat( _vm.passwordRules),"counter":"99","maxlength":"99","minlength":"8","required":"","autofocus":"","disabled":_vm.loading,"autocomplete":"new-password"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
          var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading || !_vm.valid,"loading":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.auth.PASSWORD_BUTTON'))+" ")])],1)],1)]]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }