/* eslint-disable no-console */

import { Hub } from '@aws-amplify/core';
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registered(registration: ServiceWorkerRegistration): void {
      setInterval((): void => {
        registration.update();
      }, 1000 * 60 * 60); // hourly checks
    },
    updated(): void {
      Hub.dispatch('appAlert', {
        event: 'success',
        message: '$vuetify.app.UPDATE',
        data: (): void => window.location.reload()
      });
    }
  });
}
