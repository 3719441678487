var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Splitpanes',{class:{
    'default-theme': true,
    'pa-3': true,
    'pl-1': _vm.$vuetify.breakpoint.mdAndUp
  },staticStyle:{"flex-grow":"1","height":"calc(100vh - 64px)","overflow":"hidden"},attrs:{"horizontal":""}},[_c('Pane',{ref:"requests",staticClass:"v-card v-sheet"},[_c('div',{staticClass:"subtitle-1 text-center header-sticky font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.console.REQUESTS'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"right":"16px"},attrs:{"icon":"","small":"","absolute":""},on:{"click":function($event){return _vm.requests.splice(0)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiDelete))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.console.CLEAR'))+" ")])])],1),_vm._l((_vm.requests),function(request,index){return _c('highlightjs',{key:index,style:(index !== 0 ? "margin-top: 4px" : ''),attrs:{"language":"http","code":request}})})],2),_c('Pane',{ref:"responses",staticClass:"v-card v-sheet"},[_c('div',{staticClass:"subtitle-1 text-center header-sticky font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.console.RESPONSES'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"right":"16px"},attrs:{"icon":"","small":"","absolute":""},on:{"click":function($event){return _vm.responses.splice(0)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiDelete))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.console.CLEAR'))+" ")])])],1),_vm._l((_vm.responses),function(response,index){return _c('highlightjs',{key:index,style:(index !== 0 ? "margin-top: 4px" : ''),attrs:{"language":"http","code":response}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }