import type { AuthOptions } from '@aws-amplify/auth/lib-esm/types';

export const VERSION: string = process.env.APP_VERSION as string;
export const STROMEE_ENVIRONMENT: string = 'dev';
export const STROMEE_PLUS_ENVIRONMENT: string = 'prod';
export const ACCOUNT: string = process.env.ACCOUNT as string;
export const REGION: string = process.env.REGION as string;
export const STROMEE_SERVICE_PREFIX: string = 'stromee';
export const STROMEE_PLUS_SERVICE_PREFIX: string = 'stromee-link';
export const STROMEE_DEFAULT_PREFIX: string = `${STROMEE_SERVICE_PREFIX}-${STROMEE_ENVIRONMENT}`;
export const STROMEE_PLUS_DEFAULT_PREFIX: string = `${STROMEE_PLUS_SERVICE_PREFIX}-${STROMEE_PLUS_ENVIRONMENT}`;
export const PRODUCTION_TABLE: string = `${STROMEE_PLUS_DEFAULT_PREFIX}-devices`;
export const LINKING_TABLE: string = `${STROMEE_PLUS_DEFAULT_PREFIX}-linking`;
export const DEVICE_INDEX: string = 'device';
export const CUSTOMER_TABLE: string = process.env.CUSTOMER_TABLE as string;
export const SUB_INDEX: string = 'sub';
export const DEVICE_POLICY: string = `${STROMEE_PLUS_DEFAULT_PREFIX}-DevicePolicy`;
export const THING_NAME_PREFIX: string = `${STROMEE_PLUS_DEFAULT_PREFIX}_`;
export const THING_ARN_PREFIX: string = `arn:aws:iot:${REGION}:${ACCOUNT}:thing/${THING_NAME_PREFIX}`;
export const THING_GROUP_ARN_PREFIX: string = `arn:aws:iot:${REGION}:${ACCOUNT}:thinggroup/${STROMEE_PLUS_DEFAULT_PREFIX}`;
export const THING_GROUP_ALL: string = THING_GROUP_ARN_PREFIX;
export const THING_GROUP_GATEWAY: string = `${THING_GROUP_ARN_PREFIX}-gateway`;
export const THING_GROUP_INTERFACE: string = `${THING_GROUP_ARN_PREFIX}-interface`;
export const THING_GROUP_OTHER: string = `${THING_GROUP_ARN_PREFIX}-other`;
export const PRODUCTION_API_KEY: string = process.env
  .PRODUCTION_API_KEY as string;
export const STROMEE_PLUS_API_DOMAIN: string = process.env
  .STROMEE_PLUS_API_DOMAIN as string;
export const STROMEE_CUSTOMER_API_DOMAIN: string = process.env
  .STROMEE_CUSTOMER_API_DOMAIN as string;
export const UID_REGEX: RegExp = new RegExp(
  `^${THING_NAME_PREFIX}[GIT]-(?:0[1-9]|[12][0-9]|3[01])(?:0[1-9]|1[012])(?:19|20)\\d\\d-\\d{8}$`,
  'i'
);
export const ADMIN_USER_GROUP: string = process.env.ADMIN_USER_GROUP as string;
export const ADMIN_ROLE: string = process.env.ADMIN_ROLE as string;
export const SUPPORT_USER_GROUP: string = process.env
  .SUPPORT_USER_GROUP as string;
export const SUPPORT_ROLE: string = process.env.SUPPORT_ROLE as string;
export const OTA_BUCKET: string = `${STROMEE_PLUS_DEFAULT_PREFIX}-ota`;
export const OTA_ROLE: string = `arn:aws:iam::${ACCOUNT}:role/${OTA_BUCKET}-service-role`;
export const SIGNING_PROFILE: string = 'stromee_link_prod';
export const AMPLIFY_CONFIG: {
  Auth: Omit<
    Required<AuthOptions>,
    'cookieStorage' | 'oauth' | 'refreshHandlers' | 'storage' | 'clientMetadata'
  >;
} = {
  Auth: {
    userPoolId: process.env.USER_POOL_ID as string,
    userPoolWebClientId: process.env.USER_POOL_CLIENT_ID as string,
    identityPoolId: process.env.IDENTITY_POOL_ID as string,
    region: REGION,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    identityPoolRegion: REGION,
    endpoint: `https://${STROMEE_CUSTOMER_API_DOMAIN}/v1/auth/cognito`
  }
};
