import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule
} from 'vuex-module-decorators';
import { VuexPersistence } from 'vuex-persist';

Vue.use(Vuex);

interface StoreType {
  settings: SettingsModule;
}

const vuexLocal: VuexPersistence<StoreType> = new VuexPersistence<StoreType>({
  storage: localStorage
});

const store: Store<StoreType> & {
  $app: Vue;
} = new Store<StoreType>({
  plugins: [vuexLocal.plugin]
}) as Store<StoreType> & {
  $app: Vue;
};

class ModuleWithStore extends VuexModule {
  store!: Store<StoreType> & {
    $app: Vue;
  };
}

@Module({
  name: 'settings',
  dynamic: true,
  store: store,
  namespaced: true,
  preserveState: localStorage.getItem('vuex') !== null
})
export class SettingsModule extends ModuleWithStore {
  public locale: 'system' | 'de' | 'en' = 'system';
  public dark: 'system' | boolean = 'system';
  public console: boolean = false;
  public ignoreVersion: boolean = false;

  public get actualDark(): boolean {
    return SettingsModule.getActualDark(this.dark);
  }

  public get actualLocale(): string {
    return SettingsModule.getActualLocale(this.locale);
  }

  @Mutation
  private _setLocale(locale: 'system' | 'de' | 'en'): void {
    this.locale = locale;
  }

  @Mutation
  private _setDark(dark: 'system' | boolean): void {
    this.dark = dark;
  }

  @MutationAction({ mutate: ['console'] })
  public async setConsole(console: boolean): Promise<{ console: boolean }> {
    return { console };
  }

  @MutationAction({ mutate: ['ignoreVersion'] })
  public async setIgnoreVersion(
    ignoreVersion: boolean
  ): Promise<{ ignoreVersion: boolean }> {
    return { ignoreVersion };
  }

  @Action({ commit: '_setDark' })
  public setDark(dark: 'system' | boolean): boolean | 'system' {
    if (!['system', true, false].includes(dark)) {
      dark = 'system';
    }
    this.store.$app.$vuetify.theme.dark = SettingsModule.getActualDark(dark);
    return dark;
  }

  @Action({ commit: '_setLocale' })
  public setLocale(locale: 'system' | 'de' | 'en'): 'system' | 'de' | 'en' {
    if (!['system', 'de', 'en'].includes(locale)) {
      locale = 'system';
    }
    const actualLocale: string = SettingsModule.getActualLocale(locale);
    this.store.$app.$vuetify.lang.current = actualLocale;
    this.store.$app.$moment.locale(actualLocale);
    return locale;
  }

  private static getActualDark(dark: 'system' | boolean): boolean {
    return dark !== 'system'
      ? dark
      : window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  private static getActualLocale(locale: string): string {
    if (locale === 'system') {
      locale = (
        navigator.language ||
        (navigator as unknown as { browserLanguage: string }).browserLanguage ||
        (navigator as unknown as { userLanguage: string }).userLanguage ||
        'en-US'
      ).split('-')[0];
    }
    if (!['de', 'en'].includes(locale)) {
      locale = 'en';
    }
    return locale;
  }
}

export default store;
