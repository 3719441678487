import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from './locale/de';
import en from './locale/en';

Vue.use(Vuetify);

export interface VuetifyInit {
  dark: boolean;
  locale: string;
}

export default ({ dark, locale }: VuetifyInit): Vuetify =>
  new Vuetify({
    theme: {
      dark,
      options: {
        customProperties: true
      },
      themes: {
        light: {
          primary: '#4646EB',
          secondary: '#fce33f',
          accent: '#8573ff',
          error: '#EE1453',
          info: '#4646EB',
          success: '#8BEEBC',
          warning: '#fce33f'
        },
        dark: {
          primary: '#fce33f',
          secondary: '#4646EB',
          accent: '#8573ff',
          error: '#EE1453',
          info: '#4646EB',
          success: '#2E933C',
          warning: '#fce33f'
        }
      }
    },
    icons: {
      iconfont: 'mdiSvg'
    },
    lang: {
      locales: { de, en },
      current: locale
    }
  });
